<template>
  <div id="container" v-title data-title="中国紫微斗数排盘 ChinaZwds.com">
    <van-nav-bar nav-bar-background-color="blue" title="填写信息" />
    <van-form>
      <van-field
        v-model="ArchivesValue"
        is-link
        readonly
        label="档案"
        placeholder="请选择用户档案"
        v-if="showSelectArchives"
        @click="SelectArchives = true"
      />
      <van-popup v-model="SelectArchives" round position="bottom">
        <van-search
          v-model="ArchivesKewWord"
          style="width: 100%; display: block"
          value=""
          placeholder="请输入关键词"
          use-action-slot
          @input="archivesInputChange"
          bind:search="ArchiVague"
        >
          <view slot="action" bind:tap="ArchiVague" style="color: #1296db">
            搜索
          </view>
        </van-search>
        <view class="grafting">无数据</view>

        <van-cascader
          v-model="ArchivesIdValue"
          title="请选择档案信息"
          :options="ArchivesList"
          @close="SelectArchives = false"
          @finish="onArchiFinish"
        />
      </van-popup>
      <van-field
        v-model="UserInfoForChild.Name"
        name="Name"
        label="命主姓名"
        placeholder="请填写姓名"
        :rules="[{ required: true }]"
      />
      <van-field name="Sex" label="命主性别">
        <template #input>
          <van-radio-group
            v-model="UserInfoForChild.Sex"
            direction="horizontal"
          >
            <van-radio name="1">男</van-radio>
            <van-radio name="0">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="BirType" label="生日类型">
        <template #input>
          <van-radio-group v-model="BirType" direction="horizontal">
            <van-radio name="chusheng" @click="BirTypeClick('chusheng')"
              >按出生地时间</van-radio
            >
            <van-radio name="jingdu" @click="BirTypeClick('jingdu')"
              >按经度时间</van-radio
            >
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="BirthDate"
        type="digit"
        label="出生日期"
        placeholder="出生日期,格式: yyyyMMdd"
      />
      <van-field
        v-model="BirthTime"
        type="digit"
        label="出生时分"
        placeholder="出生时分,格式: HHmm"
      />
      <van-field
        v-model="CityValue"
        is-link
        readonly
        label="城市"
        placeholder="请选择所在城市"
        v-if="showSelectProvCity"
        @click="SelectProvCity = true"
      />
      <van-popup v-model="SelectProvCity" round position="bottom">
        <van-search
          v-model="CityKewWord"
          style="width: 100%; display: block"
          value=""
          placeholder="请输入关键词"
          use-action-slot
          @input="handleInputChange"
          bind:search="UserVague"
        >
          <view slot="action" bind:tap="UserVague" style="color: #1296db">
            搜索
          </view>
        </van-search>
        <view class="grafting">无数据</view>

        <van-cascader
          v-model="CityIdValue"
          title="请选择所在地区"
          :options="CityList"
          @close="SelectProvCity = false"
          @finish="onProvFinish"
        />
      </van-popup>
      <!-- <van-field
        v-model="CurCity.Longitude"
        name="Longitude"
        label="经度"
        placeholder="请输入经度"
        v-if="showSelectProvCity"
      /> -->
      <div class="btndiv" v-if="showConver">
        <van-button type="primary" class="start-btn" @click="StartComput()">
          开始换算
        </van-button>
      </div>
      <div class="computeDiv" v-if="showComputeDiv">
        <p>北京时间：{{ formattedDate }} {{ hour }}点{{ minute }}分</p>
        <p>出生城市：{{ this.CurCity.City }}</p>
        <p>
          北京经度116.24 - {{ this.CurCity.City }}经度{{
            this.CurCity.Longitude
          }}
          = {{ this.CurCity.DecJingdu }}度
        </p>
        <p v-if="CurCity.DecJingdu < 0">
          （两地经度差值，如果小于0，代表在北京西面，需减时差）
        </p>
        <p v-else-if="CurCity.DecJingdu > 0">
          （两地经度差值，如果大于0，代表在北京东面，需加时差）
        </p>
        <p>
          {{ CurCity.DecJingdu }}度*4分钟=
          {{ computedjingdushu }}分钟（四舍五入，余
          {{ computedjingdushu }}分钟，不计算秒）
        </p>
        <p>出生时间：{{ BirthDayTimeTestStr }}（{{ ComputeHour }}）</p>
        <p>阴历日期：{{ BirthDayNongli }}</p>
        <p>出生时辰：{{ BirthHourStr }}</p>
      </div>
      <div class="btndiv" v-if="showSubmit">
        <van-button type="info" class="start-btn" @click="onSubmit()"
          >开始排盘</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<style lang="less">
.btndiv {
  height: auto;
  overflow: hidden;
  text-align: center;
}
.computeDiv {
  padding: 1rem;
}
</style>
<script>
import Vue from "vue";
import moment from "moment"; //导入moment.js库
import { Toast } from "vant";
import bus from "./Js/EventBus.js";
import { ref } from "vue";
import "@vant/touch-emulator";

export default {
  data() {
    return {
      //子组件的值，将来希望传给父组件
      BirType: "chusheng", //生日输入类型
      UserInfoForChild: {
        type: "", //传参 member-会员中心，article-文章
        ArchivesId: "", //传参 档案ID
        OrderDiskId: "", //传参 发盘ID
        Name: "匿名",
        Sex: "1", //性别：1-男,0-女
        Birthday: "",
        BirthdayStr: "",
        Hours: null, //小时
        HoursStr: "", //时辰文字
        Minute: null, //分钟
        MinuteStr: "", //分钟文字
      },
      HoursList: [
        { text: "子时", value: 1 },
        { text: "丑时", value: 2 },
        { text: "寅时", value: 3 },
        { text: "卯时", value: 4 },
        { text: "辰时", value: 5 },
        { text: "巳时", value: 6 },
        { text: "午时", value: 7 },
        { text: "未时", value: 8 },
        { text: "申时", value: 9 },
        { text: "酉时", value: 10 },
        { text: "戌时", value: 11 },
        { text: "亥时", value: 12 },
      ],
      ProvList: [], //省份列表数据
      CityList: [], //城市列表数据
      ArchivesList: [], //档案列表数据
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2050, 12, 31),
      currentDate: new Date(),
      value: "",
      showHours: false, //显示选择时辰
      showBirthday: false, //显示选择生日
      showConver: false, //显示换算按钮
      showSubmit: true, //显示提交按钮
      showSelectArchives: false, //选择档案组件
      showSelectProvCity: false, //选择省市组件
      showComputeDiv: false, //时间计算说明
      SelectArchives: false, //选择档案下拉框
      SelectProvCity: false, //选择省市下拉框
      ArchivesKewWord: "", //档案搜索关键词
      CityKewWord: "", //城市搜索关键词
      BirthDate: "", //出生日期值 yyyyMMdd
      BirthTime: "", //出生时间 HHmm
      TimeStr: "", //时辰
      BirthDayTimeTest: new Date(), //生日时间计算后的时间
      BirthDayTimeTestStr: "", //生日时间计算后的时间格式化
      BirthDayNongli: "", //计算后的农历生日
      BirthHourStr: "", //计算后的农历时辰
      HourList: [
        "错误",
        "子",
        "丑",
        "寅",
        "卯",
        "辰",
        "巳",
        "午",
        "未",
        "申",
        "酉",
        "戌",
        "亥",
      ], //时辰数据列表
      ComputeHour: "", //计算后的日期和时辰解释
      ArchivesValue: "", //选择档案值
      ArchivesIdValue: "", //选择档案Id
      CurArchives: {
        Id: "", //主键ID
        Name: "", //姓名
        type: "", //类型，1-分组，2-用户
      },
      CityValue: "", //选择城市值
      CityIdValue: "", //城市ID
      CurCity: {
        Id: "", //主键ID
        City: "", //城市名称
        Longitude: 0, //经度
        DecBeijing: 0, //减北京时间
        DecJingdu: 0, //经度差
        DecMinute: 0, //每度分钟
        AdjustTime: 0, //调整结果时间
      },
    };
  },
  mounted() {
    if (sessionStorage.getItem("access_token")) {
      //console.log("组件中的，已登录，存在token");
      this.showSelectArchives = true;
      //当认证不为空时，读取档案列表
      this.getArchives();
    } else {
      //console.log("组件中的，未登录");
    }
    const type = this.$route.query.type; //请求类型 member-会员中心，article-文章
    //检测请求参数，如果是
    if (type == "member") {
      this.UserInfoForChild.ArchivesId = this.$route.query.ArchivesId;
      this.UserInfoForChild.type = type;
      if (
        this.UserInfoForChild.ArchivesId === "" ||
        this.UserInfoForChild.ArchivesId === null
      ) {
        Toast("档案ID不能为空");
        return false;
      } else {
        //将父组件的comName值 改为紫微斗数排盘组件
        this.$emit("ComNameFromSon", "ZwdsPP");
        //向PaiPan组件传递参数值
        this.$nextTick(async function () {
          bus.$emit("ReceiveUserinfo", this.UserInfoForChild);
        });
      }
    } else if (type == "article") {
      this.UserInfoForChild.OrderDiskId = this.$route.query.OrderDiskId;
      this.UserInfoForChild.type = type;
      if (
        this.UserInfoForChild.OrderDiskId === "" ||
        this.UserInfoForChild.OrderDiskId === null
      ) {
        Toast("发盘ID不能为空");
        return false;
      } else {
        //将父组件的comName值 改为紫微斗数排盘组件
        this.$emit("ComNameFromSon", "ZwdsPP");
        //向PaiPan组件传递参数值
        this.$nextTick(async function () {
          bus.$emit("ReceiveUserinfo", this.UserInfoForChild);
        });
      }
    }
  },
  created() {
    //默认生日为当前日期
    const time = this.currentDate;
    //暂时不要给生日赋初始值，由程序计算后得到值，避免出现冲突
    // this.UserInfoForChild.Birthday = moment(time).format("YYYY-MM-DD");
    // this.BirthDate = moment(time).format("YYYYMMDD");
    // this.BirthTime = "0000";
    // this.UserInfoForChild.BirthdayStr = `${time.getFullYear()}年${
    //   time.getMonth() + 1
    // }月${time.getDate()}日`;
    // //默认时辰为子时
    // this.UserInfoForChild.Hours = 1;
    // this.UserInfoForChild.HoursStr = "子时";
    //加载时，读取城市列表
    this.getCity();
  },
  methods: {
    //点击开始计算
    StartComput() {
      /**
       * 点击开始计算后的业务逻辑
       * 1、检测年月日输入是否正确
       * 2、检测时分是否输入正确
       * 3、是否选择了城市经度
       * 4、一切准备就绪后，由后端计算当前时间加上分钟后的 公历和农历时间
       */
      if (
        this.formattedDate === null ||
        this.formattedDate === undefined ||
        this.formattedDate === ""
      ) {
        Toast("请填写正确的生日！");
        return false;
      }
      if (
        this.hour === undefined ||
        this.minute === undefined ||
        this.hour === "" ||
        this.minute === ""
      ) {
        Toast("请填写正确的出生时分！");
        return false;
      }
      if (this.CityIdValue === null || this.CityIdValue === "") {
        Toast("请选择城市！");
        return false;
      }
      //给生日赋值
      this.UserInfoForChild.Birthday = moment(this.BirthDate).format(
        "YYYY-MM-DD"
      );
      this.UserInfoForChild.Hours = this.hour;
      this.UserInfoForChild.Minute = this.minute;
      if (
        this.UserInfoForChild.Birthday != null &&
        this.UserInfoForChild.Birthday != ""
      ) {
        this.showComputeDiv = true;
        /**
         * 1、计算时间由后端计算
         * 2、计算方式为：传入Date1值和需要加减的分钟
         * 3、返回Date2的公历时间和农历时间，以及时辰
         * 3、返回两者对比后的相差方式
         */
        //准备计算当前时间加上经度时间后，是否还是当前日期
        const year = this.UserInfoForChild.Birthday.split("-")[0];
        const month = this.UserInfoForChild.Birthday.split("-")[1] - 1; // 月份从0开始计数，因此需要减去1
        const day = this.UserInfoForChild.Birthday.split("-")[2];
        this.BirthDayTimeTest = new Date(
          year,
          month,
          day,
          this.UserInfoForChild.Hours,
          this.UserInfoForChild.Minute
        );

        this.BirthDate = moment(new Date(year, month, day)).format("YYYYMMDD");
        this.UserInfoForChild.BirthdayStr = moment(
          new Date(year, month, day)
        ).format("YYYY年MM月DD日");
        //此处请求接口，开始计算
        this.$nextTick(async function () {
          const { data: res } = await this.$http({
            method: "get",
            url:
              this.$baseUrl +
              "/ZWDS/CompareDate?t=" +
              new Date().getTime() +
              "&Date1=" +
              this.UserInfoForChild.Birthday +
              " " +
              this.UserInfoForChild.Hours +
              ":" +
              this.UserInfoForChild.Minute +
              "&minute=" +
              this.computedjingdushu,
            params: {},
          });
          if (res.code != 200) {
            Toast(res.msg);
            return false;
          }
          if (res.data.RemindStatus == 1) {
            this.ComputeHour = "在当日，当时辰";
          } else if (res.data.RemindStatus == 2) {
            this.ComputeHour = "在当日，不当时辰";
          } else if (res.data.RemindStatus == 3) {
            this.ComputeHour = "不当日，当时辰";
          } else if (res.data.RemindStatus == 4) {
            this.ComputeHour = "不当日，不当时辰";
          }
          //加上经度分钟后的时间
          this.BirthDayTimeTest = res.data.CCDate2;
          //赋值计算后的农历
          this.BirthDayNongli = res.data.CCDate2;
          //赋值计算后的时辰
          this.BirthHourStr = res.data.CCHour2;
          //赋值计算后的时辰数字，以返回的时辰取第二个字
          // this.UserInfoForChild.Hours = this.HourList.indexOf(
          //   this.BirthHourStr.split("")[1]
          // );
          //赋值计算后的小时数字，直接取接口返回的小时时间
          this.UserInfoForChild.Hours = res.data.GOHour2;
          //赋值计算后的时间，以返回的时间字进行格式化
          this.BirthDayTimeTestStr = moment(res.data.Date2).format(
            "YYYY年MM月DD日 HH点mm分"
          );
        });
        //计算后的生日时间，传入排盘系统
        this.UserInfoForChild.Birthday = moment(this.BirthDayTimeTest).format(
          "YYYY-MM-DD"
        );
        //计算后的时辰，传入排盘系统
        this.UserInfoForChild.BirthdayStr = moment(
          this.BirthDayTimeTest
        ).format("YYYY年MM月DD日");
        //计算后的小时
        this.UserInfoForChild.Hours = moment(this.BirthDayTimeTest).format(
          "HH"
        );
        //计算后的分钟
        this.UserInfoForChild.Minute = moment(this.BirthDayTimeTest).format(
          "mm"
        );
        this.showSubmit = true; //显示提交排盘按钮
        this.showConver = false; //隐藏换算按钮
      } else {
        Toast("请输入正确的日期");
        return false;
      }
    },
    //时辰数字(输入小时)
    GetShichen(hour) {
      const currhour = parseInt(hour);
      var shichen = 0;
      if (hour > 23 || hour < 0) {
        Toast("时辰输入不正确！");
        return false;
      }
      if (currhour === 23 || currhour < 1) {
        shichen = 1;
      }
      if (currhour >= 1 && currhour < 3) {
        shichen = 2;
      }
      if (currhour >= 3 && currhour < 5) {
        shichen = 3;
      }
      if (currhour >= 5 && currhour < 7) {
        shichen = 4;
      }
      if (currhour >= 7 && currhour < 9) {
        shichen = 5;
      }
      if (currhour >= 9 && currhour < 11) {
        shichen = 6;
      }
      if (currhour >= 11 && currhour < 13) {
        shichen = 7;
      }
      if (currhour >= 13 && currhour < 15) {
        shichen = 8;
      }
      if (currhour >= 15 && currhour < 17) {
        shichen = 9;
      }
      if (currhour >= 17 && currhour < 19) {
        shichen = 10;
      }
      if (currhour >= 19 && currhour < 21) {
        shichen = 11;
      }
      if (currhour >= 21 && currhour < 23) {
        shichen = 12;
      }
      return shichen;
    },
    //点击生日输入类型触发
    BirTypeClick(type) {
      this.BirType = type;
      if (type === "chusheng") {
        //如果选择出生地时间，则显示以下内容
        this.showSelectProvCity = false; //隐藏省市选择组件
        this.showSubmit = true; //显示提交排盘按钮
        this.showConver = false; //隐藏换算按钮
      } else if (type === "jingdu") {
        //如果选择经度时间，则显示以下内容
        this.showSelectProvCity = true; //显示省市选择组件
        this.showSubmit = false; //隐藏提交排盘按钮
        this.showConver = true; //显示开始换算按钮
      }
    },
    //获取所有档案列表
    getArchives() {
      this.$nextTick(async function () {
        try {
          const { data: res } = await this.$http({
            headers: {
              Authorization: "Bearer " + Vue.prototype.$access_token,
            },
            method: "get",
            url:
              this.$webUrl +
              "/Member/ZWDSPP/GetArchivesTree?t=" +
              new Date().getTime(),
            params: {},
          });
          if (res.code != 200) {
            Toast(res.msg);
            return false;
          }
          res.data.forEach((item) => {
            const childrenList = [];

            item.archivesList.forEach((childItem) => {
              const newChildItem = {
                text: childItem.Name,
                value: childItem.Id,
                type: childItem.type,
                Name: item.Name,
                Sex: childItem.Gender,
                Birthday: childItem.Birthday,
                Hours: childItem.Hours,
                Minute: childItem.Minute,
              };
              childrenList.push(newChildItem);
            });
            //根据需求重新组合字段
            const newItem = {
              text: item.Name,
              value: item.Id,
              type: item.type,
              children: childrenList,
            };
            this.ArchivesList.push(newItem);
          });
        } catch (err) {
          Toast("获取档案树发生错误，原因：" + err.message);
        }
      });
    },
    //搜索档案触发
    archivesInputChange(item) {
      this.CityList = [];
      this.CityKewWord = item;
      this.$nextTick(async function () {
        const { data: res } = await this.$http({
          method: "get",
          url: this.$baseUrl + "/ZWDS/GetAllCityList?t=" + new Date().getTime(),
          params: { Name: item },
        });
        if (res.code != 200) {
          Toast(res.msg);
          return false;
        }
        res.data.forEach((item) => {
          const newChildItem = [];
          //根据需求重新组合字段
          const newItem = {
            text: item.Name,
            value: item.Id,
            type: item.type,
            Name: item.Name,
            Sex: item.Sex,
            Birthday: item.Birthday,
            Hours: item.Hours,
            Minute: item.Minute,
          };
          this.ArchivesList.push(newItem);
        });
      });
    },
    //选择档案完成操作后触发
    onArchiFinish({ selectedOptions }) {
      /**
       * 点击选择档案完成操作后触发
       * 1、将选择档案设置为false
       * 2、得到档案人员的姓名
       * 3、得到档案人员的Id
       */
      var lastIndex = selectedOptions.length;
      const ArchiObj = selectedOptions[lastIndex - 1];
      if (ArchiObj.type != "2") {
        Toast("请选择人员");
        return false;
      }

      //给生日赋值
      this.UserInfoForChild.Birthday = moment(ArchiObj.Birthday).format(
        "YYYY-MM-DD"
      );
      this.UserInfoForChild.Name = ArchiObj.text;
      this.UserInfoForChild.Hours = ArchiObj.Hours;
      this.UserInfoForChild.Minute = ArchiObj.Minute;
      this.UserInfoForChild.Sex = ArchiObj.Sex;
      this.BirthDate = moment(this.UserInfoForChild.Birthday).format(
        "YYYYMMDD"
      );
      this.BirthTime = ArchiObj.Hours + ArchiObj.Minute;
      //Toast("人员选择完毕");

      this.SelectArchives = false;
      this.ArchivesValue = selectedOptions
        .map((option) => option.text)
        .join("/");
    },

    //获取所有城市列表
    getCity() {
      this.$nextTick(async function () {
        try {
          const { data: res } = await this.$http({
            method: "get",
            url:
              this.$baseUrl + "/ZWDS/GetAllCityList?t=" + new Date().getTime(),
            params: {},
          });
          if (res.code != 200) {
            Toast(res.msg);
            return false;
          }
          res.data.forEach((item) => {
            const newChildItem = [];
            //根据需求重新组合字段
            const newItem = {
              text: item.City,
              value: item.Id,
              longitude: item.Longitude,
              decBeijing: item.DecBeijing,
              decJingdu: item.DecJingdu,
              decMinute: item.DecMinute,
              adjustTime: item.AdjustTime,
            };
            this.CityList.push(newItem);
          });
        } catch (err) {
          Toast("发生错误，原因：" + err.message);
        }
      });
    },
    //搜索城市触发
    handleInputChange(item) {
      this.CityList = [];
      this.CityKewWord = item;
      this.$nextTick(async function () {
        const { data: res } = await this.$http({
          method: "get",
          url: this.$baseUrl + "/ZWDS/GetAllCityList?t=" + new Date().getTime(),
          params: { Name: item },
        });
        if (res.code != 200) {
          Toast(res.msg);
          return false;
        }
        res.data.forEach((item) => {
          const newChildItem = [];
          //根据需求重新组合字段
          const newItem = {
            text: item.City,
            value: item.Id,
            longitude: item.Longitude,
            decBeijing: item.DecBeijing,
            decJingdu: item.DecJingdu,
            decMinute: item.DecMinute,
            adjustTime: item.AdjustTime,
          };
          this.CityList.push(newItem);
        });
      });
    },
    //选择城市完成操作后触发
    onProvFinish({ selectedOptions }) {
      this.SelectProvCity = false;
      this.CityValue = selectedOptions.map((option) => option.text).join("/");
      const cityObj = selectedOptions[0];
      this.CurCity = {
        Id: cityObj.value, //主键Id
        City: cityObj.text, //城市名称
        Longitude: cityObj.longitude, //经度
        DecBeijing: cityObj.decBeijing, //减北京时间
        DecJingdu: cityObj.decJingdu, //经度差
        DecMinute: cityObj.decMinute, //每度分钟
        AdjustTime: cityObj.adjustTime, //调整结果时间
      };
      this.CityIdValue = selectedOptions
        .map((option) => option.value)
        .join("/");
    },
    //选择生日
    OnConfirmBirthday(time) {
      this.showBirthday = false;
      const timestr = `${time.getFullYear()}年${
        time.getMonth() + 1
      }月${time.getDate()}日`; // ${time.getHours()}时${time.getMinutes()}分
      this.value = timestr;
      this.UserInfoForChild.Birthday = `${time.getFullYear()}-${
        time.getMonth() + 1
      }-${time.getDate()}`;
      this.UserInfoForChild.Hours = `${time.getHours()}`;
    },
    //选择时辰
    OnConfirmHours({ selectedOptions }) {
      this.showHours = false;

      this.UserInfoForChild.HoursStr = selectedOptions
        .map((option) => option.text)
        .join("/");
      this.UserInfoForChild.Hours = selectedOptions
        .map((option) => option.value)
        .join("/");
    },

    //提交
    onSubmit(values) {
      if (
        this.UserInfoForChild.Name === null ||
        this.UserInfoForChild.Name === ""
      ) {
        Toast("请输入姓名");
        return false;
      }
      if (this.BirthDate === null || this.BirthDate === "") {
        Toast("请输入生日");
        return false;
      }
      if (this.BirthTime === null || this.BirthTime === "") {
        Toast("请输入出生时间");
        return false;
      }
      if (this.hour === "" || this.minute === "") {
        Toast("出生时间输入不正确");
        return false;
      }
      //如果是按出生地时间，则每次提交都需要从输入框拿数值
      if (this.BirType === "chusheng") {
        this.UserInfoForChild.Birthday = moment(this.BirthDate).format(
          "YYYY-MM-DD"
        );
        //this.UserInfoForChild.Hours = this.GetShichen(this.hour);
        this.UserInfoForChild.Hours = this.hour;
      }
      if (this.UserInfoForChild.Birthday === "Invalid date") {
        Toast("生日输入不正确");
        return false;
      }
      console.log(this.UserInfoForChild);
      //将父组件的comName值 改为紫微斗数排盘组件
      this.$emit("ComNameFromSon", "ZwdsPP");
      //向PaiPan组件传递参数值
      this.$nextTick(async function () {
        bus.$emit("ReceiveUserinfo", this.UserInfoForChild);
      });
    },
  },
  computed: {
    //计算经度差值
    computedjingdushu() {
      if (this.CurCity.DecMinute != 0 && this.CurCity.DecJingdu != 0) {
        const testNum = this.CurCity.DecMinute * this.CurCity.DecJingdu;
        return testNum.toFixed(0);
      } else {
        return 0;
      }
    },
    //格式化生日,并判断日期输入是否准确
    formattedDate() {
      if (this.BirthDate && this.BirthDate.length === 8) {
        const year = parseInt(this.BirthDate.slice(0, 4));
        const month = parseInt(this.BirthDate.slice(4, 6)) - 1; // 月份从0开始计数，因此需要减去1
        const day = parseInt(this.BirthDate.slice(6, 8));
        if (year < 1950 || year > 2050) {
          this.UserInfoForChild.Birthday = null;
          console.log("错误");
        } else if (month < 0 || month > 12) {
          this.UserInfoForChild.Birthday = null;
          console.log("错误");
        } else if (day < 1 || day > 31) {
          this.UserInfoForChild.Birthday = null;
          console.log("错误");
        } else {
          const dateObj = new Date(year, month, day);
          const formattedDate = moment(dateObj).format("YYYY-MM-DD");
          return moment(dateObj).format("YYYY年MM月DD日"); // 根据需求设置日期格式
        }
      } else {
        this.UserInfoForChild.Birthday = null;
        console.log("错误");
      }
    },
    //格式化小时，并判断小时输入是否准确
    hour() {
      const input = this.BirthTime;
      if (this.BirthTime && this.BirthTime.length === 4) {
        if (isNaN(Number(input))) {
          return ""; // 非数字则返回空字符串
        } else {
          let hours = Math.floor(Number(input) / 100); // 提取前两位作为小时部分
          if (hours > 23 || hours < 0) {
            return ""; // 超出范围则返回'无效'
          } else {
            this.UserInfoForChild.Hours = String(hours).padStart(2, "0");
            return String(hours).padStart(2, "0"); // 格式化成两位数并添加前导零
          }
        }
      } else {
        return "";
      }
    },

    //格式化分钟，并判断分钟输入是否准确
    minute() {
      const input = this.BirthTime;
      if (this.BirthTime && this.BirthTime.length === 4) {
        if (isNaN(Number(input))) {
          return ""; // 非数字则返回空字符串
        } else {
          let minutes = Number(input) % 100; // 提取后两位作为分钟部分

          if (minutes >= 60 || minutes < 0) {
            return ""; // 超出范围则返回'无效'
          } else {
            this.UserInfoForChild.Minute = String(minutes).padStart(2, "0");
            return String(minutes).padStart(2, "0"); // 格式化成两位数并添加前导零
          }
        }
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
</style>
<template>
  <div id="container">
    <van-button
      type="info"
      @click="TestClick()"
      :loading="showloading"
      loading-type="spinner"
      icon="replay"
      >信息按钮</van-button
    >
  </div>
</template>

<script>
import Vue from "vue";
import { Button, Checkbox, CheckboxGroup } from "vant";

Vue.use(Checkbox);
Vue.use(CheckboxGroup);
export default {
  data() {
    return {
      showloading: false,
      spinnerText: "",
    };
  },
  methods: {
    TestClick() {
      console.log("触发按钮");
      this.showloading = true;
    },
  },
};
</script>

<style>
</style>
import Cookies from 'js-cookie'

// 封装一些常用的cookie操作方法
const cookieMethods = {
    get: function (name) {
        return Cookies.get(name)
    },
    set: function (name, value, options) {
        Cookies.set(name, value, options)
    },
    remove: function (name) {
        Cookies.remove(name);
    }
}

export default cookieMethods
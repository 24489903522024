<template>
  <div id="container" v-title data-title="紫微斗数排盘">
    <div class="bigzwdsbox" ref="bigzwdsbox">
      <div class="large-square" ref="largesquare">
        <ul class="ziweidoushu">
          <li>中国紫微斗数:ChinaZwds.com<br /></li>
        </ul>
        <ul class="basicinfo">
          <li>
            <span
              >姓名：<label class="xingmingfont">{{
                baseinfo.XingMing
              }}</label></span
            ><span>性别：{{ baseinfo.XingBie }}</span>
            <span>年龄：{{ baseinfo.NianLing }}</span>
          </li>
          <li>
            <span>星座：{{ baseinfo.XingZuo }}</span>
            <span>命局：{{ baseinfo.MingJu }}</span
            ><span>命卦：{{ baseinfo.MingGua }}</span>
          </li>
          <li>
            <span class="whole"
              >公历：<label class="gonglifont">{{
                baseinfo.GongLi
              }}</label></span
            >
            <span class="whole"
              >农历：<label class="nonglifont">{{
                baseinfo.NongLi
              }}</label></span
            >
          </li>
        </ul>
        <ul class="sizhubazi">
          <li class="bazichild">
            <span class="shishen">{{ baseinfo.NianShiShen }}</span>
            <span class="bazi">{{ baseinfo.NianZhu }}</span>
            <dl class="nayinshishen">
              <dt>{{ baseinfo.NianNaYin }}</dt>
              <dd
                v-for="(item, index) in baseinfo.NianZhuTGSS"
                v-if="item != null"
              >
                {{ item.TG }}<em>{{ item.SS }}</em>
              </dd>
            </dl>
            <dl class="shensha nianshensha">
              <dd
                v-for="(item, index) in baseinfo.NianShenSha"
                v-if="item != null"
              >
                {{ item }}
              </dd>
            </dl>
          </li>
          <li class="bazichild">
            <span class="shishen">{{ baseinfo.YueShiShen }}</span>
            <span class="bazi">{{ baseinfo.YueZhu }}</span>
            <dl class="nayinshishen">
              <dt>{{ baseinfo.YueNaYin }}</dt>
              <dd
                v-for="(item, index) in baseinfo.YueZhuTGSS"
                v-if="item != null"
              >
                {{ item.TG }}<em>{{ item.SS }}</em>
              </dd>
            </dl>
            <dl class="shensha yueshensha">
              <dd
                v-for="(item, index) in baseinfo.YueShenSha"
                v-if="item != null"
              >
                {{ item }}
              </dd>
            </dl>
          </li>
          <li class="bazichild">
            <span class="shishen">{{ baseinfo.RiShiShen }}</span>
            <span class="bazi">{{ baseinfo.RiZhu }}</span>
            <dl class="nayinshishen">
              <dt>{{ baseinfo.RiNaYin }}</dt>
              <dd
                v-for="(item, index) in baseinfo.RiZhuTGSS"
                v-if="item != null"
              >
                {{ item.TG }}<em>{{ item.SS }}</em>
              </dd>
            </dl>
            <dl class="shensha rishensha">
              <dd
                v-for="(item, index) in baseinfo.RiShenSha"
                v-if="item != null"
              >
                {{ item }}
              </dd>
            </dl>
          </li>
          <li class="bazichild">
            <span class="shishen">{{ baseinfo.ShiShiShen }}</span>
            <span class="bazi">{{ baseinfo.ShiZhu }}</span>
            <dl class="nayinshishen">
              <dt>{{ baseinfo.ShiNaYin }}</dt>
              <dd
                v-for="(item, index) in baseinfo.ShiZhuTGSS"
                v-if="item != null"
              >
                {{ item.TG }}<em>{{ item.SS }}</em>
              </dd>
            </dl>
            <dl class="shensha shishensha">
              <dd
                v-for="(item, index) in baseinfo.ShiShenSha"
                v-if="item != null"
              >
                {{ item }}
              </dd>
            </dl>
          </li>
        </ul>
        <!--八字起运岁数-->
        <ul class="baziqiyun">
          <li
            class="qiyunchild"
            v-for="(item, index) in bzqylist"
            v-if="item != null"
          >
            <span class="qiyunchild-shishen">{{ item.shishen }}</span>
            <span class="qiyunchild-ganzhi">{{ item.ganzhi }}</span>
            <span class="qiyunchild-nianling">{{ item.nianling }}</span>
          </li>
        </ul>
        <!--八字起运岁数结束-->
        <Canvas
          ref="child_Canvas"
          :init_Quare_Width="quareWidth"
          :init_Quare_Height="quareHeight"
        ></Canvas>
      </div>
      <!--循环十二宫格-->
      <div
        v-for="(item, index) in this.zwpplist"
        v-if="item != null"
        @click="drawTriangle(item.GWXH)"
        :class="'small-square box' + (index + 1)"
      >
        <!--主星、副星、丙级星 zx_zh、fx_zh、x5_zh-->
        <!-- <ul v-for="(xing, index) in item.GWStarts" :class="xing | startType"> -->
        <ul
          v-for="(xing, index) in item.GWStarts"
          :class="computedstartType(xing)"
        >
          <li>
            <!--星名称-->
            <span :class="xing | startColor">{{
              xing != null && xing.split("_").length > 1
                ? xing.split("_")[1]
                : ""
            }}</span>
            <!--星耀类型-->
            <span class="x_mx">{{
              xing != null && xing.split("_").length > 2
                ? xing.split("_")[2]
                : ""
            }}</span>
            <!--先天四化-->
            <span class="x_sh">
              <span class="x_sh_m">{{
                (xing != null && xing.split("_").length > 1
                  ? xing.split("_")[1]
                  : "") | getXTSH(item)
              }}</span>
            </span>
            <!--大限四化-->
            <span class="x_sh">
              <span
                :id="
                  (xing != null && xing.split('_').length > 1
                    ? xing.split('_')[1]
                    : '') + '_x'
                "
                class="x_sh_x"
              ></span>
            </span>
            <!--流年四化-->
            <span class="x_sh">
              <span
                :id="
                  (xing != null && xing.split('_').length > 1
                    ? xing.split('_')[1]
                    : '') + '_n'
                "
                class="x_sh_n"
              ></span>
            </span>
            <!--流月四化-->
            <span class="x_sh">
              <span
                :id="
                  (xing != null && xing.split('_').length > 1
                    ? xing.split('_')[1]
                    : '') + '_y'
                "
                class="x_sh_y"
              ></span>
            </span>
            <!--流日四化-->
            <span class="x_sh">
              <span
                :id="
                  (xing != null && xing.split('_').length > 1
                    ? xing.split('_')[1]
                    : '') + '_r'
                "
                class="x_sh_r"
              ></span>
            </span>
          </li>
        </ul>
        <!--主星、副星、丙级星 结束-->

        <!--博士、岁前、将前-->
        <ul v-if="showbingji" class="bs-sq-jq">
          <li v-for="(xing, index) in item.GWBSZX" v-if="item != null">
            <span>{{
              xing != null && xing.split("_").length > 1
                ? xing.split("_")[1]
                : ""
            }}</span>
          </li>
          <li v-for="(xing, index) in item.GWXXFX" v-if="item != null">
            <span>{{
              xing != null && xing.split("_").length > 1
                ? xing.split("_")[1]
                : ""
            }}</span>
          </li>
        </ul>
        <!--博士、岁前、将前 结束-->

        <!--流时、流日、流月-->
        <ul class="ls-lr-ly">
          <li class="ls_li"><span></span></li>
          <li class="lr_li"><span></span></li>
          <li class="ly_li"><span></span></li>
        </ul>
        <!--流时、流日、流月 结束-->
        <!--流年、运宫、命宫-->
        <ul class="ln-yg-mg">
          <li class="ln_li"><span></span></li>
          <li class="dx_li"><span></span></li>
          <li class="xt_li">
            <span>{{ item.GWNAME }}</span>
          </li>
        </ul>
        <!--流时、流日、流月 结束-->
        <!--月份、星耀、干支-->
        <ul class="yf-xy-gz">
          <li class="yf_li"><span>月份</span></li>
          <li>
            <span>{{ item.XYNL }}</span>
          </li>
          <li>
            <span>{{
              item.GWTGDZ != null && item.GWTGDZ.split("_").length > 1
                ? item.GWTGDZ.split("_")[1]
                : ""
            }}</span>
          </li>
          <li>
            <span>{{
              item.GWTGDZ != null && item.GWTGDZ.split("_").length > 1
                ? item.GWTGDZ.split("_")[0]
                : ""
            }}</span>
          </li>
        </ul>
        <!--月份、星耀、干支 结束-->
        <!--大限年龄-->
        <ul class="dx-nld">
          <li>
            <span>{{ item.GWNLXS }}</span>
          </li>
        </ul>
        <!--大限年龄结束-->
        <!--安星系天干-->
        <ul class="axxtg"></ul>
        <!--安星系天干结束-->
      </div>
      <!--循环十二宫格结束-->
    </div>
    <!--中间工具栏-->
    <div class="gongjuqu">
      <span class="childspan">
        <van-checkbox
          v-model="nimingcheck"
          shape="square"
          :icon-size="computedCheckPX"
          :disabled="IsAnonymous"
          @click="nimingclick(this)"
          >匿名</van-checkbox
        >
      </span>
      <span class="childspan">
        <van-checkbox
          v-model="jianpaicheck"
          shape="square"
          :icon-size="computedCheckPX"
          @click="jianpaiclick(this)"
          >简排</van-checkbox
        >
      </span>
      <span class="childspan">
        <van-button
          type="info"
          size="mini"
          :style="computedButtonPX"
          icon="certificate"
          @click="fapan()"
          >发盘</van-button
        >
      </span>
      <span class="childspan">
        <van-button
          type="info"
          size="mini"
          :style="computedButtonPX"
          icon="exchange"
          @click="jiepan()"
          >解盘</van-button
        >
      </span>
      <!-- <span class="childspan">
        <van-button
          type="info"
          size="mini"
          :style="computedButtonPX"
          icon="photo-o"
          @click="jietu()"
          >截图</van-button
        >
      </span> -->
      <span class="childspan">
        <van-button
          type="primary"
          size="mini"
          :loading="showloadingPlus"
          loading-type="spinner"
          :style="computedButtonPX"
          @click="shichenplus()"
          icon="plus"
          >时辰</van-button
        >
      </span>
      <span class="childspan">
        <van-button
          type="warning"
          size="mini"
          :loading="showloadingDec"
          loading-type="spinner"
          :style="computedButtonPX"
          @click="shichendec()"
          icon="minus"
          >时辰</van-button
        >
      </span>
      <span class="childspan">
        <van-button
          type="info"
          size="mini"
          :style="computedButtonPX"
          icon="replay"
          @click="reset()"
          >重排</van-button
        >
      </span>
    </div>
    <!--中间工具栏结束-->

    <!--底部大限流年按钮-->
    <div class="daxianliunian">
      <ul>
        <li class="daxian">
          <dl>
            <dt>大限</dt>
            <dd>
              <span
                v-for="(item, index) in this.dxlist"
                v-if="item != null"
                :class="'dxgwxh' + item.GWXH"
                @click="
                  dxclick(
                    item.GWXH,
                    'dxgwxh' + item.GWXH,
                    item.GWNLXS,
                    item.GWGZ,
                    item.inDate
                  )
                "
              >
                <em>{{ item.GWNLXS }}</em>
                <em>{{ item.GWGZ }}</em>
              </span>
            </dd>
          </dl>
        </li>
        <li class="liunian">
          <dl>
            <dt>流年</dt>
            <dd>
              <span
                v-for="(item, index) in this.lnlist"
                v-if="item != null"
                :class="'lngwxh' + item.GWXH"
                @click="
                  lnclick(
                    item.GWXH,
                    'lngwxh' + item.GWXH,
                    item.GWNLXS,
                    item.GWGZ,
                    item.inDate
                  )
                "
              >
                <em>{{ item.GWNLXS }}</em>
                <em>{{ item.GWGZ }}</em>
              </span>
            </dd>
          </dl>
        </li>
        <li class="liuyue">
          <dl>
            <dt>流月</dt>
            <dd>
              <span
                v-for="(item, index) in this.lylist"
                v-if="item != null"
                :class="'lygwxh' + item.GWXH"
                @click="
                  lyclick(
                    item.GWXH,
                    'lygwxh' + item.GWXH,
                    item.GWNLXS,
                    item.GWGZ,
                    item.inDate
                  )
                "
              >
                <em>{{ item.GWNLXS }}</em>
                <em>{{ item.GWGZ }}</em>
              </span>
            </dd>
          </dl>
        </li>
        <li class="liuri">
          <dl>
            <dt>流日</dt>
            <dd>
              <span
                v-for="(item, index) in this.lrlist"
                v-if="item != null"
                :class="'lrgwxh' + item.GWXH"
                @click="
                  lrclick(
                    item.GWXH,
                    'lrgwxh' + item.GWXH,
                    item.GWNLXS,
                    item.GWGZ,
                    item.inDate
                  )
                "
              >
                <em>{{ item.GWNLXS }}</em>
                <em>{{ item.GWGZ }}</em>
              </span>
            </dd>
          </dl>
        </li>
        <li class="liushi">
          <dl>
            <dt>流时</dt>
            <dd>
              <span
                v-for="(item, index) in this.lslist"
                v-if="item != null"
                :class="'lsgwxh' + item.GWXH"
                @click="
                  lsclick(
                    item.GWXH,
                    'lsgwxh' + item.GWXH,
                    item.GWNLXS,
                    item.GWGZ,
                    item.inDate
                  )
                "
              >
                <em>{{ item.GWNLXS }}</em>
                <em>{{ item.GWGZ }}</em>
              </span>
            </dd>
          </dl>
        </li>
      </ul>
    </div>
    <!--底部大限流年按钮结束-->
    <!--今日的阳历和农历日期-->
    <div class="rili_box">
      <span>公历：{{ curGongli }}</span>
      <span>农历：{{ curNongli.ChineseDateString }}</span>
      <span>您的浏览器尺寸: {{ screenWidth }}</span>
    </div>
    <!--今日的阳历和农历日期结束-->
  </div>
</template>

<script>
import moment from "moment"; //导入moment.js库
import bus from "./Js/EventBus.js";
import $ from "jquery";
import Canvas from "@/components/Canvas.vue";

import Vue from "vue";
import { Toast, Button, Checkbox, CheckboxGroup } from "vant";
Vue.use(Checkbox);
Vue.use(CheckboxGroup);

export default {
  data() {
    return {
      screenWidth: 0, //屏幕宽度
      screenHeight: 0, //屏幕高度
      type: "", //传参 member-会员中心，article-文章
      ArchivesId: "", //传参 档案ID
      OrderDiskId: "", //传参 发盘ID
      IsAnonymous: false, //是否为匿名
      curGongli: "", //当前公历
      curNongli: "", //当前农历
      isSubmit: true, //允许请求开关
      original_width: 300,
      quareWidth: 0, //大方块宽度
      quareHeight: 0, //大方块高度
      zwpplist: [null], //紫微排盘list
      baseinfo: [null], //接口返回的基础信息
      bzqylist: [null], //八字起运返回的信息
      Birthday: "1986-06-24", //请求参数 生日
      Hours: 3, //请求参数 小时
      Name: "匿名", //请求参数 姓名
      Sex: "1", //请求参数 性别：1-男，0-女
      Sfnl: "", //是否农历
      Sfry: "", //是否闰月
      Cur_MG: 0, //请求参数 命宫
      AiType: 0, //类型：0-先天，1-大限，2-流年，3-流月，4-流日，5-流时
      tgdzStr: "壬辰", //天干地支
      dxlnInStr: "", //大限流年输入参数，大限-年龄段，流年-年份，流月-月份，流日-每月哪天，流时-时辰
      dxlist: [null], //大限年龄段list
      dxpplist: [null], //大限排盘list 同紫微排盘的格式一致
      lnlist: [null], //流年数据list
      lnpplist: [null], //流年排盘list 同紫微排盘的格式一致
      lnyflist: [null], //流年月份数据list
      lylist: [null], //流月数据list
      lypplist: [null], //流月排盘list 同紫微排盘的格式一致
      lrlist: [null], //流日数据list
      lrpplist: [null], //流日排盘list 同紫微排盘的格式一致
      lslist: [null], //流时数据list
      lspplist: [null], //流时排盘list 同紫微排盘的格式一致
      nimingcheck: true, //是否匿名，默认为匿名
      showbingji: true, //是否显示丙级星
      jianpaicheck: false, //是否简排，默认为非简排
      showloadingPlus: false, //时辰+是否显示加载中
      showloadingDec: false, //时辰-是否显示加载中
    };
  },
  created() {
    //接收UserInfo过来的参数
    bus.$on("ReceiveUserinfo", async (val) => {
      this.type = val.type; //传参 member-会员中心，article-文章
      this.ArchivesId = val.ArchivesId; //传参 档案ID
      this.OrderDiskId = val.OrderDiskId; //传参 发盘ID
      if (this.type == "member") {
        const { data: res } = await this.$http({
          method: "get",
          url: this.$baseUrl + "/ZWDS/GetArchives?t=" + new Date().getTime(),
          params: {
            Id: this.ArchivesId,
          },
        });
        if (res.code != 200) {
          console.log(res.msg);
          return false;
        }
        this.Name = res.data.Name;
        this.Sex = res.data.Gender;
        this.Birthday = res.data.Birthday;
        this.Hours = res.data.Hours;
        const time = new Date();
        this.curGongli = `${time.getFullYear()}年${
          time.getMonth() + 1
        }月${time.getDate()}日`; // ${time.getHours()}时${time.getMinutes()}分
        this.curNongli = "";
        this.$nextTick(async function () {
          //调用紫微斗数先天排盘方法
          this.getZWDSPaipan();
          //调用公历转农历方法
          this.getNongli();
          // console.log("小时：" + this.FromUserinfo.Hours);
          this.IsAnonymous = false;
          this.nimingcheck = false;
        });
      } else if (this.type == "article") {
        const { data: res } = await this.$http({
          method: "get",
          url: this.$baseUrl + "/ZWDS/GetOrderDisk?t=" + new Date().getTime(),
          params: {
            Id: this.OrderDiskId,
          },
        });
        if (res.code != 200) {
          console.log(res.msg);
          return false;
        }
        this.IsAnonymous = res.data.IsAnonymous == 1 ? true : false;
        this.Name = res.data.Name;
        this.Sex = res.data.Gender == "男" ? 1 : 0;
        this.Birthday = res.data.RealBirthday;
        this.Hours = res.data.Hours;

        const time = new Date();
        this.curGongli = `${time.getFullYear()}年${
          time.getMonth() + 1
        }月${time.getDate()}日`; // ${time.getHours()}时${time.getMinutes()}分
        this.curNongli = "";
        this.$nextTick(async function () {
          //调用紫微斗数先天排盘方法
          this.getZWDSPaipan();
          //调用公历转农历方法
          this.getNongli();
          // console.log("小时：" + this.FromUserinfo.Hours);
        });
      } else {
        this.Name = val.Name;
        this.Sex = val.Sex;
        this.Birthday = val.Birthday;
        this.Hours = val.Hours;
        const time = new Date();
        this.curGongli = `${time.getFullYear()}年${
          time.getMonth() + 1
        }月${time.getDate()}日`; // ${time.getHours()}时${time.getMinutes()}分
        this.curNongli = "";
        this.$nextTick(async function () {
          //调用紫微斗数先天排盘方法
          this.getZWDSPaipan();
          //调用公历转农历方法
          this.getNongli();
          // console.log("小时：" + this.FromUserinfo.Hours);
        });
      }
    });
  },
  //在html结构渲染到浏览器以后，初始化函数
  mounted() {
    this.original_width = this.$refs.largesquare.offsetWidth;
    this.quareWidth = this.$refs.largesquare.offsetWidth;
    this.quareHeight = this.$refs.largesquare.offsetHeight;

    //检测到window尺寸发生变化触发
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    //根据窗口尺寸的变化，自动计算复选框的默认尺寸
    computedCheckPX() {
      const val = this.quareWidth;
      if (val >= 350) {
        return 20;
      } else if (val >= 300 && val < 350) {
        return 18;
      } else if (val >= 250 && val < 300) {
        return 15;
      } else if (val >= 200 && val < 250) {
        return 13;
      } else if (val < 200) {
        return 10;
      }
    },
    //根据窗口尺寸的变化，自动计算按钮的高度尺寸
    computedButtonPX() {
      const val = this.quareWidth;
      if (val >= 350) {
        return "height:20px;";
      } else if (val >= 300 && val < 350) {
        return "height:18px;";
      } else if (val >= 250 && val < 300) {
        return "height:15px;";
      } else if (val >= 200 && val < 250) {
        return "height:13px;";
      } else if (val < 200) {
        return "height:10px;";
      }
    },
    //根据传入参数及简排参数自动计算类名
    computedstartType() {
      return function (val) {
        if (val != "") {
          var type = val.split("_")[0];
          switch (type) {
            case "1":
              return "zx_zh";
            case "2":
              return "fx_zh";
            case "3":
              return this.showbingji === true ? "x5_zh" : "x5_zh_none";
            default:
              return "";
          }
        }
        return "";
      };
    },
  },
  methods: {
    //点击大限后触发
    //gwxh:宫位序号
    //dxspan:大限span的类名
    //dxlnInStr: 大限流年输入参数
    //gwgz:宫位干支
    dxclick(gwxh, dxspan, dxlnInStr, gwgz, inDate) {
      //大限流年点击触发
      $(".x_sh_x").text(""); //清理大限四化
      $(".x_sh_n").text(""); //清理流年四化
      $(".x_sh_y").text(""); //清理流月四化
      $(".x_sh_r").text(""); //清理流日四化

      //清理安星系
      $("ul.axxtg li.dx").remove(); //清理大限安星系
      $("ul.axxtg li.ln").remove(); //清理流月安星系
      $("ul.axxtg li.ly").remove(); //清理流月安星系
      $("ul.axxtg li.lr").remove(); //清理流日安星系
      $("ul.axxtg li.ls").remove(); //清理流时安星系

      $(".daxian dl dd span").removeClass("cur"); //删除大限当前标识
      $(".liunian dl dd span").removeClass("cur"); //删除流年当前标识
      $(".liuyue dl dd span").removeClass("cur"); //删除流月当前标识
      $(".liuri dl dd span").removeClass("cur"); //删除流日当前标识
      $(".liushi dl dd span").removeClass("cur"); //删除流时当前标识

      //设置当前span为选中状态
      $("." + dxspan).addClass("cur");
      //设置大限宫位名称为显示状态
      $(".dx_li").show();

      //操作流年列表
      $(".liunian").show();
      $(".ln_li").hide();

      //操作流月列表
      $(".liuyue").hide();
      $(".ly_li").hide();
      $(".yf_li").hide();

      //操作流日列表
      $(".liuri").hide();
      $(".lr_li").hide();

      //操作流时列表
      $(".liushi").hide();
      $(".ls_li").hide();

      //输入大限年龄段，得到流年列表
      this.$nextTick(async function () {
        if (!this.isSubmit) {
          console.log("请不要过快操作！");
        }
        this.isSubmit = false;
        const { data: res } = await this.$http({
          method: "get",
          url: this.$baseUrl + "/ZWDS/GetDXLN?t=" + new Date().getTime(),
          params: {
            Birthday: this.Birthday,
            Hours: this.Hours,
            Name: this.Name,
            AiType: 1,
            tgdzStr: gwgz,
            dxlnInStr: dxlnInStr,
            inDate: inDate,
            Sex: this.Sex,
          },
        });
        if (res.code != 200) {
          console.log(res.msg);
          Toast(res.msg);
          this.isSubmit = true;
          return false;
        }
        //请求数据后，给相应的对象赋值
        this.dxpplist = res.data.PaiPan; //请求数据后，获取排盘数据列表
        this.lnlist = res.data.DXLN; //获取大限流年的数据，进行页面渲染
        for (var i = 0; i < this.dxpplist.length; i++) {
          const item = this.dxpplist[i];
          //循环设置每个宫位的大限宫位名称
          $(".box" + (i + 1) + " .dx_li span").text(item.GWNAME);

          //如果该宫位的大限四化不为空，则渲染大限四化数据
          if (this.dxpplist[i].DXSH.length > 0) {
            var shList = this.dxpplist[i].DXSH;
            for (var k = 0; k < shList.length; k++) {
              var shItem = shList[k].split("_");
              $("#" + shItem[1] + "_x").text(shItem[0]);
            }
          }

          //如果该宫位的大限安星系不为空，则渲染大限安星系数据
          if (this.dxpplist[i].DXStars.length > 0) {
            var xxList = this.dxpplist[i].DXStars;
            $(".box" + (i + 1) + " ul.axxtg")
              .children("li.dx")
              .remove();
            for (var k = 0; k < xxList.length; k++) {
              $(".box" + (i + 1) + " ul.axxtg").append(
                '<li class="dx">' + "<span>" + xxList[k] + "</span>" + "</li>"
              );
            }
          }

          //如果宫位星系辅星不为空，则渲染宫位星系辅星数据
          if (this.dxpplist[i].GWXXFX.length > 0) {
            //查询紫微斗数排盘中的GWXXFX
            const zwppGwIndex = this.zwpplist.findIndex(
              (item) => item.GWXH === this.dxpplist[i].GWXH
            );
            //如果该对象存在，则替换相应的对象数据
            if (zwppGwIndex !== -1) {
              this.zwpplist[zwppGwIndex].GWXXFX = this.dxpplist[i].GWXXFX;
            }
          }
        }
        this.isSubmit = true;
        this.Cur_MG = res.data.Info.MingGong; //将命宫数字赋值给当前命宫
        //触发三角形位置
        this.$refs.child_Canvas.drawTriangle(
          res.data.Info.MingGong,
          this.quareWidth,
          this.quareHeight
        );
      });
    },
    //点击流年后触发
    lnclick(gwxh, lnspan, dxlnInStr, gwgz, inDate) {
      $(".x_sh_n").text(""); //清理流年四化
      $(".x_sh_y").text(""); //清理流月四化
      $(".x_sh_r").text(""); //清理流日四化

      $("ul.axxtg li.ln").remove(); //清理流年安星系
      $("ul.axxtg li.ly").remove(); //清理流月安星系
      $("ul.axxtg li.lr").remove(); //清理流日安星系
      $("ul.axxtg li.ls").remove(); //清理流时安星系

      //操作流年列表
      $(".liunian dl dd span").removeClass("cur"); //删除流年当前标识
      $(".liuyue dl dd span").removeClass("cur"); //删除流月当前标识
      $(".liuri dl dd span").removeClass("cur"); //删除流日当前标识
      $(".liushi dl dd span").removeClass("cur"); //删除流时当前标识

      //设置当前span为选中状态
      $("." + lnspan).addClass("cur");
      //设置流年宫位名称为显示状态
      $(".ln_li").show();

      //操作流月列表
      $(".liuyue").show();
      //设置月份名称为显示状态
      $(".yf_li").show();
      $(".ly_li").hide();

      //操作流日列表
      $(".liuri").hide();
      $(".lr_li").hide();

      //操作流时列表
      $(".liushi").hide();
      $(".ls_li").hide();

      //输入流年年份，得到流月列表
      this.$nextTick(async function () {
        if (!this.isSubmit) {
          console.log("请不要过快操作！");
        }
        const { data: res } = await this.$http({
          method: "get",
          url: this.$baseUrl + "/ZWDS/GetDXLN?t=" + new Date().getTime(),
          params: {
            Birthday: this.Birthday,
            Hours: this.Hours,
            Name: this.Name,
            AiType: 2,
            tgdzStr: gwgz,
            dxlnInStr: dxlnInStr,
            inDate: inDate,
            Sex: this.Sex,
          },
        });
        if (res.code != 200) {
          console.log(res.msg);
          Toast(res.msg);
          this.isSubmit = true;
          return false;
        }
        //请求数据后，给相应的对象赋值
        this.lnpplist = res.data.PaiPan; //请求数据后，获取排盘数据列表
        this.lylist = res.data.DXLN; //获取大限流年的数据，进行页面渲染
        this.lnyflist = res.data.LNYF; //获取大限流年的流年月份数据，进行页面渲染
        for (var i = 0; i < this.lnyflist.length; i++) {
          const item = this.lnpplist[i];
          //循环设置每个宫位的月份名称
          $(".box" + (i + 1) + " .yf_li span").text(item.GWNLXS);
        }
        for (var i = 0; i < this.lnpplist.length; i++) {
          const item = this.lnpplist[i];
          //循环设置每个宫位的宫位名称
          $(".box" + (i + 1) + " .ln_li span").text(item.GWNAME);

          //如果该宫位的四化不为空，则渲染四化数据
          if (this.lnpplist[i].LNSH.length > 0) {
            var shList = this.lnpplist[i].LNSH;
            for (var k = 0; k < shList.length; k++) {
              var shItem = shList[k].split("_");
              $("#" + shItem[1] + "_n").text(shItem[0]);
            }
          }

          //如果该宫位的安星系不为空，则渲染安星系数据
          if (this.lnpplist[i].LNStars.length > 0) {
            var xxList = this.lnpplist[i].LNStars;
            $(".box" + (i + 1) + " ul.axxtg")
              .children("li.ln")
              .remove();
            for (var k = 0; k < xxList.length; k++) {
              $(".box" + (i + 1) + " ul.axxtg").append(
                '<li class="ln">' + "<span>" + xxList[k] + "</span>" + "</li>"
              );
            }
          }

          //如果宫位星系辅星不为空，则渲染宫位星系辅星数据
          if (this.lnpplist[i].GWXXFX.length > 0) {
            //查询紫微斗数排盘中的GWXXFX
            const zwppGwIndex = this.zwpplist.findIndex(
              (item) => item.GWXH === this.lnpplist[i].GWXH
            );
            //如果该对象存在，则替换相应的对象数据
            if (zwppGwIndex !== -1) {
              this.zwpplist[zwppGwIndex].GWXXFX = this.lnpplist[i].GWXXFX;
            }
          }
        }
        //触发三角形位置
        this.Cur_MG = res.data.Info.MingGong; //将命宫数字赋值给当前命宫
        this.$refs.child_Canvas.drawTriangle(
          res.data.Info.MingGong,
          this.quareWidth,
          this.quareHeight
        );
      });
    },
    //点击流月后触发
    lyclick(gwxh, lyspan, dxlnInStr, gwgz, inDate) {
      $(".x_sh_y").text(""); //清理流月四化
      $(".x_sh_r").text(""); //清理流日四化

      //清理安星系
      $("ul.axxtg li.ly").remove(); //清理流月安星系
      $("ul.axxtg li.lr").remove(); //清理流日安星系
      $("ul.axxtg li.ls").remove(); //清理流时安星系

      //操作流年列表
      $(".liuyue dl dd span").removeClass("cur"); //删除流月当前标识
      $(".liuri dl dd span").removeClass("cur"); //删除流日当前标识
      $(".liushi dl dd span").removeClass("cur"); //删除流时当前标识

      //设置当前span为选中状态
      $("." + lyspan).addClass("cur");
      //设置流月宫位名称为显示状态
      $(".ly_li").show();

      //操作流日列表
      $(".liuri").show();
      $(".lr_li").hide();

      //操作流时列表
      $(".liushi").hide();
      $(".ls_li").hide();

      //输入流月月份，得到流日列表
      this.$nextTick(async function () {
        if (!this.isSubmit) {
          console.log("请不要过快操作！");
        }
        const { data: res } = await this.$http({
          method: "get",
          url: this.$baseUrl + "/ZWDS/GetDXLN?t=" + new Date().getTime(),
          params: {
            Birthday: this.Birthday,
            Hours: this.Hours,
            Name: this.Name,
            AiType: 3,
            tgdzStr: gwgz,
            dxlnInStr: dxlnInStr,
            inDate: inDate,
            Sex: this.Sex,
          },
        });
        if (res.code != 200) {
          console.log(res.msg);
          Toast(res.msg);
          this.isSubmit = true;
          return false;
        }
        //请求数据后，给相应的对象赋值
        this.lypplist = res.data.PaiPan; //请求数据后，获取排盘数据列表
        this.lrlist = res.data.DXLN; //获取大限流年的数据，进行页面渲染
        for (var i = 0; i < this.lypplist.length; i++) {
          const item = this.lypplist[i];
          //循环设置每个宫位的宫位名称
          $(".box" + (i + 1) + " .ly_li span").text(item.GWNAME);

          //如果该宫位的四化不为空，则渲染四化数据
          if (this.lypplist[i].LYSH.length > 0) {
            var shList = this.lypplist[i].LYSH;
            for (var k = 0; k < shList.length; k++) {
              var shItem = shList[k].split("_");
              $("#" + shItem[1] + "_y").text(shItem[0]);
            }
          }

          //如果该宫位的安星系不为空，则渲染安星系数据
          if (this.lypplist[i].LYStars.length > 0) {
            var xxList = this.lypplist[i].LYStars;
            $(".box" + (i + 1) + " ul.axxtg")
              .children("li.ly")
              .remove();
            for (var k = 0; k < xxList.length; k++) {
              $(".box" + (i + 1) + " ul.axxtg").append(
                '<li class="ly">' + "<span>" + xxList[k] + "</span>" + "</li>"
              );
            }
          }

          //如果宫位星系辅星不为空，则渲染宫位星系辅星数据
          if (this.lypplist[i].GWXXFX.length > 0) {
            //查询紫微斗数排盘中的GWXXFX
            const zwppGwIndex = this.zwpplist.findIndex(
              (item) => item.GWXH === this.lypplist[i].GWXH
            );
            //如果该对象存在，则替换相应的对象数据
            if (zwppGwIndex !== -1) {
              this.zwpplist[zwppGwIndex].GWXXFX = this.lypplist[i].GWXXFX;
            }
          }
        }
        //触发三角形位置
        this.Cur_MG = res.data.Info.MingGong; //将命宫数字赋值给当前命宫
        this.$refs.child_Canvas.drawTriangle(
          res.data.Info.MingGong,
          this.quareWidth,
          this.quareHeight
        );
      });
    },

    //点击流日后触发
    lrclick(gwxh, lrspan, dxlnInStr, gwgz, inDate) {
      $(".x_sh_r").text(""); //清理流日四化

      //清理安星系
      $("ul.axxtg li.lr").remove(); //清理流日安星系
      $("ul.axxtg li.ls").remove(); //清理流时安星系

      //操作流年列表
      $(".liuri dl dd span").removeClass("cur"); //删除流日当前标识
      $(".liushi dl dd span").removeClass("cur"); //删除流时当前标识

      //设置当前span为选中状态
      $("." + lrspan).addClass("cur");
      //设置流日宫位名称为显示状态
      $(".lr_li").show();

      //流时列表
      $(".liushi").show();
      $(".ls_li").hide();

      //输入流日天数，得到流时列表
      this.$nextTick(async function () {
        if (!this.isSubmit) {
          console.log("请不要过快操作！");
        }
        const { data: res } = await this.$http({
          method: "get",
          url: this.$baseUrl + "/ZWDS/GetDXLN?t=" + new Date().getTime(),
          params: {
            Birthday: this.Birthday,
            Hours: this.Hours,
            Name: this.Name,
            AiType: 4,
            tgdzStr: gwgz,
            dxlnInStr: dxlnInStr,
            inDate: inDate,
            Sex: this.Sex,
          },
        });
        if (res.code != 200) {
          console.log(res.msg);
          Toast(res.msg);
          this.isSubmit = true;
          return false;
        }
        //请求数据后，给相应的对象赋值
        this.lrpplist = res.data.PaiPan; //请求数据后，获取排盘数据列表
        this.lslist = res.data.DXLN; //获取大限流年的数据，进行页面渲染
        for (var i = 0; i < this.lrpplist.length; i++) {
          const item = this.lrpplist[i];
          //循环设置每个宫位的宫位名称
          $(".box" + (i + 1) + " .lr_li span").text(item.GWNAME);

          //如果该宫位的四化不为空，则渲染四化数据
          if (this.lrpplist[i].LRSH.length > 0) {
            var shList = this.lrpplist[i].LRSH;
            for (var k = 0; k < shList.length; k++) {
              var shItem = shList[k].split("_");
              $("#" + shItem[1] + "_r").text(shItem[0]);
            }
          }

          //如果该宫位的安星系不为空，则渲染安星系数据
          if (this.lrpplist[i].LRStars.length > 0) {
            var xxList = this.lrpplist[i].LRStars;
            $(".box" + (i + 1) + " ul.axxtg")
              .children("li.lr")
              .remove();
            for (var k = 0; k < xxList.length; k++) {
              $(".box" + (i + 1) + " ul.axxtg").append(
                '<li class="lr">' + "<span>" + xxList[k] + "</span>" + "</li>"
              );
            }
          }

          //如果宫位星系辅星不为空，则渲染宫位星系辅星数据
          if (this.lrpplist[i].GWXXFX.length > 0) {
            //查询紫微斗数排盘中的GWXXFX
            const zwppGwIndex = this.zwpplist.findIndex(
              (item) => item.GWXH === this.lrpplist[i].GWXH
            );
            //如果该对象存在，则替换相应的对象数据
            if (zwppGwIndex !== -1) {
              this.zwpplist[zwppGwIndex].GWXXFX = this.lrpplist[i].GWXXFX;
            }
          }
        }
        //触发三角形位置
        this.Cur_MG = res.data.Info.MingGong; //将命宫数字赋值给当前命宫
        this.$refs.child_Canvas.drawTriangle(
          res.data.Info.MingGong,
          this.quareWidth,
          this.quareHeight
        );
      });
    },

    //点击流时后触发
    lsclick(gwxh, lsspan, dxlnInStr, gwgz, inDate) {
      $(".x_sh_s").text(""); //清理流日四化

      //清理安星系
      $("ul.axxtg li.ls").remove(); //清理流时安星系

      //操作流年列表
      $(".liushi dl dd span").removeClass("cur"); //删除流时当前标识

      //设置当前span为选中状态
      $("." + lsspan).addClass("cur");
      //设置流时宫位名称为显示状态
      $(".ls_li").show();

      //输入流时时辰，得到流时排盘
      this.$nextTick(async function () {
        if (!this.isSubmit) {
          console.log("请不要过快操作！");
        }
        const { data: res } = await this.$http({
          method: "get",
          url: this.$baseUrl + "/ZWDS/GetDXLN?t=" + new Date().getTime(),
          params: {
            Birthday: this.Birthday,
            Hours: this.Hours,
            Name: this.Name,
            AiType: 5,
            tgdzStr: gwgz,
            dxlnInStr: dxlnInStr,
            inDate: inDate,
            Sex: this.Sex,
          },
        });
        if (res.code != 200) {
          console.log(res.msg);
          Toast(res.msg);
          this.isSubmit = true;
          return false;
        }
        //请求数据后，给相应的对象赋值
        this.lspplist = res.data.PaiPan; //请求数据后，获取排盘数据列表
        for (var i = 0; i < this.lspplist.length; i++) {
          const item = this.lspplist[i];
          //循环设置每个宫位的宫位名称
          $(".box" + (i + 1) + " .ls_li span").text(item.GWNAME);

          //如果该宫位的四化不为空，则渲染四化数据
          if (this.lspplist[i].LSSH.length > 0) {
            var shList = this.lspplist[i].LRSH;
            for (var k = 0; k < shList.length; k++) {
              var shItem = shList[k].split("_");
              $("#" + shItem[1] + "_s").text(shItem[0]);
            }
          }

          //如果宫位星系辅星不为空，则渲染宫位星系辅星数据
          if (this.lspplist[i].GWXXFX.length > 0) {
            //查询紫微斗数排盘中的GWXXFX
            const zwppGwIndex = this.zwpplist.findIndex(
              (item) => item.GWXH === this.lspplist[i].GWXH
            );
            //如果该对象存在，则替换相应的对象数据
            if (zwppGwIndex !== -1) {
              this.zwpplist[zwppGwIndex].GWXXFX = this.lspplist[i].GWXXFX;
            }
          }
        }
        //触发三角形位置
        this.Cur_MG = res.data.Info.MingGong; //将命宫数字赋值给当前命宫
        this.$refs.child_Canvas.drawTriangle(
          res.data.Info.MingGong,
          this.quareWidth,
          this.quareHeight
        );
      });
    },

    //点击匿名后触发
    nimingclick(obj) {
      if (this.IsAnonymous == true) {
        if (this.nimingcheck === false) {
          this.nimingcheck = true;
        }
        Toast("该发盘设置了匿名，不可查看！");
      } else {
        if (this.nimingcheck === true) {
          //判断如果是匿名，则隐藏信息
          $("label.xingmingfont").text("某某某");
          $("label.gonglifont").text("0000年00月00日");
          $("label.nonglifont").text("某某某某年某某月某某日");
        } else {
          //判断如果是非匿名，则显示信息
          $("label.xingmingfont").text(this.baseinfo.XingMing);
          $("label.gonglifont").text(this.baseinfo.GongLi);
          $("label.nonglifont").text(this.baseinfo.NongLi);
        }
      }
    },
    //点击简排后触发
    jianpaiclick(obj) {
      if (this.jianpaicheck === true) {
        this.showbingji = false;
      } else {
        this.showbingji = true;
      }
    },
    //加一时辰
    shichenplus() {
      if (this.Hours + 2 < 24) {
        this.Hours = this.Hours + 2;
        console.log("增加后的小时：" + this.Hours);
        //调用紫微斗数先天排盘方法
        this.showloadingPlus = true;
        this.showloadingDec = true;
        this.getZWDSPaipan();
      } else {
        Toast("时辰最大为亥时");
      }
    },
    //减一时辰
    shichendec() {
      if (this.Hours - 2 >= 0) {
        this.Hours = this.Hours - 2;
        console.log("减少后的小时：" + this.Hours);
        //调用紫微斗数先天排盘方法
        this.showloadingPlus = true;
        this.showloadingDec = true;
        this.getZWDSPaipan();
      } else {
        Toast("时辰最小为子时");
      }
    },
    //截图提示
    jietu() {
      Toast("请使用手机自带截图功能将此页面保存至您手机中！");
      return false;
    },
    //点击重排（返回信息输入界面）
    reset() {
      //将父组件的comName值 改为用户信息输入组件
      this.$router.replace({
        path: this.$route.path,
        //将url参数设置为空！
        query: { type: "", ArchivesId: "" },
      });
      window.location.href = "/";
      //this.$router.push("http://localhost:8080");
      //this.$emit("ComNameFromSon", "UserInfo");
    },
    //点击发盘（跳转至会员页面）
    fapan() {
      window.location.href = this.$webUrl + "/Member/Home/OrderPublish";
    },
    //点击解盘（触发）
    jiepan() {
      Toast("准备免费提供中");
      return false;
    },
    //获取当前农历日期
    getNongli() {
      this.$nextTick(async function () {
        const { data: res } = await this.$http({
          method: "get",
          url: this.$baseUrl + "/ZWDS/GetNongli?t=" + new Date().getTime(),
          params: {
            CurGongli: this.curGongli,
          },
        });
        if (res.code != 200) {
          console.log(res.msg);
          return false;
        }
        this.curNongli = res.data;
      });
    },
    //获取紫微斗数先天排盘
    getZWDSPaipan() {
      $(".x_sh_x").text(""); //清理大限四化
      $(".x_sh_n").text(""); //清理流年四化
      $(".x_sh_y").text(""); //清理流月四化
      $(".x_sh_r").text(""); //清理流日四化

      //清理安星系
      $("ul.axxtg li.dx").remove(); //清理大限安星系
      $("ul.axxtg li.ly").remove(); //清理流月安星系
      $("ul.axxtg li.lr").remove(); //清理流日安星系
      $("ul.axxtg li.ls").remove(); //清理流时安星系

      $(".daxian dl dd span").removeClass("cur"); //删除大限当前标识
      $(".liunian dl dd span").removeClass("cur"); //删除流年当前标识
      $(".liuyue dl dd span").removeClass("cur"); //删除流月当前标识
      $(".liuri dl dd span").removeClass("cur"); //删除流日当前标识
      $(".liushi dl dd span").removeClass("cur"); //删除流时当前标识

      //操作流年列表
      $(".liunian").hide();
      $(".ln_li").hide();

      //操作流月列表
      $(".liuyue").hide();
      $(".ly_li").hide();
      $(".yf_li").hide();

      //操作流日列表
      $(".liuri").hide();
      $(".lr_li").hide();

      //操作流时列表
      $(".liushi").hide();
      $(".ls_li").hide();
      this.$nextTick(async function () {
        const { data: res } = await this.$http({
          method: "get",
          url: this.$baseUrl + "/ZWDS/GetZWDSPaiPan?t=" + new Date().getTime(),
          params: {
            Birthday: this.Birthday,
            Hours: this.Hours,
            Sfnl: this.Sfnl,
            Sfry: this.Sfry,
            Name: this.Name,
            Sex: this.Sex,
          },
        });
        //恢复按钮状态
        this.showloadingPlus = false;
        this.showloadingDec = false;
        if (res.code != 200) {
          console.log(res.msg);
          Toast(res.msg);
          this.isSubmit = true;
          return false;
        }
        //请求数据后，给相应的对象赋值
        this.zwpplist = res.data.PaiPan; //请求数据后，获取排盘数据列表
        this.baseinfo = res.data.Info; //请求数据后，获取基础信息
        //console.log("农历日期：" + res.data.Info.NongLi);
        this.dxlist = res.data.DXLN; //请求数据后，获取大限列表
        this.bzqylist = res.data.BZQYList; //请求数据后，获取八字起运列表
        if (this.nimingcheck) {
          $("label.xingmingfont").text("某某某");
          $("label.gonglifont").text("0000年00月00日");
          $("label.nonglifont").text("某某某某年某某月某某日");
        }
        this.handleResize();
        // 调用子组件的绘制三角形方法
        this.$refs.child_Canvas.drawTriangle(
          this.baseinfo.MingGong,
          this.quareWidth,
          this.quareHeight
        ); // 绘制三角形
        //this.drawTriangle(this.baseinfo.MingGong); // 绘制三角形
      });
    },
    //检测大方块窗口尺寸变化
    handleResize(val) {
      this.screenWidth = window.innerWidth; //获取屏幕宽度
      this.screenHeight = window.innerHeight; //获取屏幕高度

      this.quareWidth = this.$refs.largesquare.offsetWidth;
      this.quareHeight = this.$refs.largesquare.offsetHeight;
      // 调用子组件的更新三角形方法
      this.$refs.child_Canvas.updateLine(
        this.Cur_MG == 0 ? this.baseinfo.MingGong : this.Cur_MG,
        this.$refs.largesquare.offsetWidth
      );
    },
    //调用子组件的绘画三角方法
    drawTriangle(GWXH) {
      // 调用子组件的更新三角形方法
      this.$refs.child_Canvas.drawTriangle(
        GWXH,
        this.quareWidth,
        this.quareHeight
      );
    },
  },
  //注册组件
  components: {
    Canvas,
  },
  //定义过滤器（vue3无过滤器）
  filters: {
    //ul的class过滤器
    startType(val) {
      if (val != "") {
        var type = val.split("_")[0];
        switch (type) {
          case "1":
            return "zx_zh";
          case "2":
            return "fx_zh";
          case "3":
            return "x5_zh";
          default:
            return "";
        }
      }
      return "";
    },
    //星颜色class过滤器
    startColor(val) {
      if (val != "") {
        var type = val.split("_")[0];
        switch (type) {
          case "1":
            return "x1_zw";
          case "2":
            return "x2_bj";
          case "3":
            return "x5_fx";
          default:
            return "";
        }
      }
      return "";
    },
    getXTSH(val, obj) {
      //获取先天四化
      if (obj.XTSH != null && obj.XTSH.length > 0) {
        for (var i = 0; i < obj.XTSH.length; i++) {
          var shStr = obj.XTSH[i].split("_");
          if (shStr.length > 1 && shStr[1] == val) {
            return shStr[0];
          }
        }
      }
      return "";
    },
  },
};
</script>

<style lang="less">
//大方格样式，包含12个小宫格

.bigzwdsbox {
  margin: 1rem auto;
  position: relative;
  width: 95vw;
  height: 95vw;
  max-width: 80rem;
  max-height: 80rem;
  border: 1px solid #000;

  .large-square {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    border: 1px solid black;
    z-index: 0;
    position: relative;
    font-size: 0.7rem;

    ul.basicinfo {
      display: block;
      float: left;
      width: 90%;

      li {
        font-size: 0.7rem;
        display: block;
      }

      li span {
        display: block;
        float: left;
        width: 30%;
        font-size: 0.7rem;
        //margin-left: 0.2rem;
      }

      li {
        span.whole {
          // display: block;
          // float: left;
          width: 50%;
          //margin-left: 0.2rem;
        }
      }
    }

    ul.sizhubazi {
      display: block;
      float: left;
      width: 67%;
      height: auto;
      overflow: hidden;
      margin-top: 0.2rem;
      line-height: 1rem;

      li {
        display: block;
        float: left;
        width: 2.5rem;
        margin-left: 6%;

        dl {
          dt {
            text-align: center;
            color: #1a8d1a;
          }

          dd {
            text-align: center;

            em {
              color: #130fff;
            }
          }
        }

        span {
          text-align: center;
          clear: both;
          display: block;
        }

        span.shishen {
          color: #130fff;
        }

        span.bazi {
          writing-mode: vertical-rl; /* 从上到下，从右到左 */
          transform: rotate(0deg); /* 可选，使文字从上到下顺序显示 */
          letter-spacing: 5px; /* 设置字母间距为5px */
          text-align: center;
          align-items: center;
          word-wrap: break-word;
          line-height: 1.1rem;
          margin: 0 auto;
          font-weight: bolder;
        }
      }
    }

    /*八字起大运通用样式 */
    ul.baziqiyun {
      display: block;
      float: left;
      width: 100%;
      height: auto;
      overflow: hidden;
      margin-top: 0.5rem;
      margin-left: 1.2rem;
      margin-top: 1rem;
      li.qiyunchild {
        display: block;
        float: left;
        margin-right: 0.2rem;
        text-align: center;
        .qiyunchild-shishen {
          display: block;
          color: #130fff;
        }
        .qiyunchild-ganzhi {
          writing-mode: vertical-rl; /* 从上到下，从右到左 */
          transform: rotate(0deg); /* 可选，使文字从上到下顺序显示 */
          letter-spacing: 5px; /* 设置字母间距为5px */
          display: block;
          margin: 0 auto;
          font-size: 1rem;
        }
        .qiyunchild-nianling {
          margin: 0 auto;
          text-align: center;
        }
      }
    }

    ul.ziweidoushu {
      display: block;
      text-align: center;
      float: left;
      width: 100%;
      height: auto;
      overflow: hidden;
      margin: 0.1rem auto;
      line-height: 1rem;
    }
  }
  //小宫格通用样式
  .small-square {
    position: absolute;
    width: 25%;
    height: 25%;

    /*定义宫格内样式*/
    ul {
      margin-top: 0.1rem;
      list-style: none;

      li span {
        text-align: center;
        font-size: 0.7rem;

        .x_mx,
        .x_sh {
          display: block;
          width: 12px;
          height: 16px;
          min-height: 0.7rem;
        }
      }
    }

    ul.x5_zh_none {
      display: none;
    }
    //主星、副星、丙级星
    ul.zx_zh li,
    ul.fx_zh li,
    ul.x5_zh li {
      margin-left: 0.01rem;
      min-width: 0.6rem;
      width: 1.5vw;
      max-width: 12px;
      height: auto;
      float: left;
      display: inline;
      text-align: center;
      line-height: 0.95rem;
    }

    //博士、岁前、将前
    ul.bs-sq-jq {
      position: absolute;
      left: 0.05rem;
      bottom: 0.2rem;

      li {
        margin-top: 0px;
        height: 0.9rem;
        font-size: 0.5rem;
      }
    }

    //流时、流日、流月
    ul.ls-lr-ly {
      position: absolute;
      left: 20%;
      bottom: 0.2rem;

      li {
        margin-top: 0px;
        height: 0.9rem;
        font-size: 0.5rem;
      }
    }

    //流年、运宫、命宫
    ul.ln-yg-mg {
      position: absolute;
      left: 40%;
      bottom: 0.2rem;

      li {
        margin-top: 0px;
        height: 0.9rem;
        font-size: 0.5rem;
      }
    }

    .dx_li {
      display: none;
    }

    .ln_li {
      display: none;
    }

    .ly_li {
      display: none;
    }

    //宫位月份隐藏
    .yf_li {
      display: none;
    }

    .ls_li {
      display: none;
    }

    .lr_li {
      display: none;
    }

    //月份、星耀、干支
    ul.yf-xy-gz {
      position: absolute;
      right: 0.05rem;
      bottom: 0.2rem;

      li {
        margin-top: 0px;
        height: 0.9rem;
        font-size: 0.5rem;
      }
    }

    //大限-年龄段
    ul.dx-nld {
      position: absolute;
      right: 0.05rem;
      top: 45%;
    }

    //安星系天干
    ul.axxtg {
      position: absolute;
      right: 0.05rem;
      top: 30%;

      li {
        float: right;
        margin-left: 0.03rem;
        min-width: 0.5rem;
        line-height: 1.2;

        span {
          display: block;
          width: 12px;
          text-align: center;
          font-size: 0.55rem;
        }
      }
    }
  }

  .small-square.box1 {
    top: 75%;
    left: 62.5%;
    transform: translateX(-50%);
    border-left: 1px solid black;
    border-right: 1px solid black;
  }

  .small-square.box2 {
    top: 75%;
    left: 37.5%;
    transform: translateX(-50%);
  }

  .small-square.box3 {
    top: 75%;
    left: 0;
    transform: translateX(-0.5%);
    border-top: 1px solid black;
    border-right: 1px solid black;
  }

  .small-square.box4 {
    top: 50%;
    left: 0;
    transform: translateX(-0.5%);
    border-top: 1px solid black;
    //border-right: 1px solid black;
  }

  .small-square.box5 {
    top: 25%;
    left: 0;
    transform: translateY(-0.5%);
    border-top: 1px solid black;
  }

  .small-square.box6 {
    top: 0;
    left: 0;
    transform: translateX(-0.5%);
    border-right: 1px solid black;
  }

  .small-square.box7 {
    top: 0;
    left: 25%;
    transform: translateX(-0.5%);
    border-right: 1px solid black;
  }

  .small-square.box8 {
    top: 0;
    left: 50%;
    transform: translateX(0%);
    border-right: 1px solid black;
  }

  .small-square.box9 {
    top: 0;
    left: 75%;
    transform: translateX(0%);
    transform: translateY(-0.5%);
    border-bottom: 1px solid black;
  }

  .small-square.box10 {
    top: 25%;
    left: 75%;
    transform: translateX(0%);
    transform: translateY(-0.5%);
    border-bottom: 1px solid black;
  }

  .small-square.box11 {
    top: 50%;
    left: 75%;
    transform: translateX(0%);
    border-bottom: 1px solid black;
  }

  .small-square.box12 {
    top: 75%;
    left: 75%;
    transform: translateX(0%);
  }
}

//定义工具去的样式
.gongjuqu {
  margin: 1rem auto;
  position: relative;
  width: 95vw;
  height: auto;
  overflow: hidden;
  max-width: 80rem;
  max-height: 80rem;
  border: 1px solid #000;
  display: flex;
  span.childspan {
    margin-top: 0;
    margin-left: 1rem;
    font-size: 0.5rem;
    vertical-align: middle;
    float: left;

    .van-checkbox__label {
      margin-left: 1px;
    }
    .van-button {
      margin-top: 0;
      height: 20px;
    }
  }
}

//定义大限流年的样式，包括大限、流年、流月、流日、流时，还有设置和应用
.daxianliunian {
  margin: 1rem auto;
  position: relative;
  width: 95vw;
  height: auto;
  max-width: 80rem;
  max-height: 80rem;
  border: 1px solid #000;
  border-bottom: 0px;

  ul {
    height: auto;
    overflow: hidden;

    li {
      height: auto;
      overflow: hidden;
      border-bottom: 1px solid #000;

      dl {
        height: auto;
        overflow: hidden;

        dt {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          float: left;
          justify-content: center;
          padding: 0px;
          font-size: 0.5rem;
          width: 38px;
          text-align: center;
          align-items: center;
          border-right: 1px solid #000;
        }

        dd {
          display: block;
          float: left;
          width: 660px;

          span {
            display: block;
            float: left;
            text-align: center;
            display: block;
            border-right: 1px solid #000;
            height: 1.5rem;
            cursor: pointer;

            em {
              margin-top: 0.15rem;
              display: block;
              font-size: 0.8rem;
              line-height: 0.5rem;
              transform: scale(0.8);
            }
          }

          span.cur {
            background-color: #6b6b6b;
            color: #fff;
          }

          span:hover {
            background-color: #e2e1e1;
          }

          span:last-child {
            border-right: 0px;
          }
        }
      }
    }

    li.daxian {
      dl {
        dt {
          height: 1.5rem;
        }

        dd {
          span {
            width: 53.5px;
          }

          span.cur {
            background-color: #6b6b6b;
            color: #fff;
          }
        }
      }
    }

    li.liunian {
      display: none;

      dl {
        dt {
          height: 1.5rem;
        }

        dd {
          span {
            width: 64.5px;
          }

          span.cur {
            background-color: #6b6b6b;
            color: #fff;
          }
        }
      }
    }

    li.liuyue {
      display: none;

      dl {
        dt {
          height: 1.5rem;
        }

        dd {
          span {
            width: 54px;
          }

          span.cur {
            background-color: #6b6b6b;
            color: #fff;
          }
        }
      }
    }

    li.liuri {
      display: none;

      dl {
        dt {
          height: 3.1rem;
        }

        dd {
          float: left;

          span:nth-child(15) {
            border-right: 0px;
          }

          span:nth-child(-n + 15) {
            border-bottom: 1px solid #000;
          }

          span {
            width: 43px;
          }

          span.cur {
            background-color: #6b6b6b;
            color: #fff;
          }
        }
      }
    }

    li.liushi {
      display: none;

      dl {
        border-bottom: 1px solid #000;

        dt {
          background-color: #4e9970;
          color: #fff;
          height: 1.5rem;
        }

        dd {
          float: left;

          span {
            width: 54px;
          }
          span.cur {
            background-color: #6b6b6b;
            color: #fff;
          }
        }
      }
    }

    li:last-child {
      border-bottom: 0px;
    }
  }
}

//定义日历的样式
.rili_box {
  margin: 1rem auto;
  position: relative;
  width: 95vw;
  height: auto;
  max-width: 80rem;
  max-height: 80rem;
  border: 1px solid #000;

  span {
    margin-left: 1rem;
    font-size: 0.5rem;
    line-height: 0.5rem;
  }
}

/*宫格内通用*/
//主星、辅星、丙级星
.x1_zw {
  color: #c00000;
  width: 12px;
  height: 32px;
}

.x2_bj {
  color: #800080;
  width: 12px;
  height: 32px;
}

.x5_fx {
  color: #03519f;
  width: 12px;
  height: 32px;
}

.x_mx {
  display: block;
  color: #6b6b6b;
  width: 12px;
  height: 16px;
}

.x_sh {
  display: block;
  width: 12px;
  height: 16px;
}

//先天
//先天四化
.x_sh_m {
  color: #ffffff;
  background: #1a8d1a;
}

//大限
//大限四化
.x_sh_x {
  color: #ffffff;
  background: #217bf0;
}

//大限宫位颜色
li.dx_li span {
  color: #217bf0;
}

//大限列表
li.daxian {
  dl {
    dt {
      background-color: #217bf0;
      color: #fff;
    }
  }
}

//大限安星系
ul.axxtg {
  li.dx {
    span {
      color: #217bf0;
    }
  }
}

//流年
//流年四化
.x_sh_n {
  color: #ffffff;
  background: #ff0000;
}

//大限宫位颜色
li.ln_li span {
  color: #ff0000;
}

//流年列表
li.liunian {
  dl {
    dt {
      background-color: #ff0000;
      color: #fff;
    }
  }
}

//流年安星系
ul.axxtg {
  li.ln {
    span {
      color: #ff0000;
    }
  }
}

//流月
//流月四化
.x_sh_y {
  color: #ffffff;
  background: #f80aec;
}

//流月宫位颜色
li.ly_li span {
  color: #f80aec;
}

//流月列表
li.liuyue {
  dl {
    dt {
      background-color: #f80aec;
      color: #fff;
    }
  }
}

//流年安星系
ul.axxtg {
  li.ly {
    span {
      color: #f80aec;
    }
  }
}

//流日
//流日四化
.x_sh_r {
  color: #ffffff;
  background: #929292;
}

//流日宫位颜色
li.lr_li span {
  color: #929292;
}

//流日列表
li.liuri {
  dl {
    dt {
      background-color: #929292;
      color: #fff;
    }
  }
}

//流时
.x_sh_s {
  color: #ffffff;
  background: #4e9970;
}

//流日宫位颜色
li.ls_li span {
  color: #4e9970;
}

//流时列表
li.liushi {
  dl {
    dt {
      background-color: #4e9970;
      color: #fff;
    }
  }
}

//手机浏览器样式
@media screen and (max-width: 380px) {
  /*手机端样式*/
  .bigzwdsbox {
    .large-square {
      ul.basicinfo {
        width: 100%;
        li {
          font-size: 0.3rem;
          line-height: 0.5rem;
          height: auto;
          overflow: hidden;
        }

        li {
          span {
            display: block;
            float: left;
            width: 30%;
            font-size: 0.3rem;
          }
          span.whole {
            margin-left: 0;
            padding-left: 0;
            width: 100%;
          }
        }
      }
      ul.sizhubazi {
        margin-top: 0.1rem;
        li.bazichild {
          font-size: 0.4rem;
          display: block;
          float: left;
          width: 1.5rem;
          line-height: 0.5rem;
          margin-left: 1%;
          span.bazi {
            font-size: 0.5rem;
            letter-spacing: 0px; /* 设置字母间距为5px */
          }
        }
      }

      ul.baziqiyun {
        margin-top: 0.1rem;
        margin-left: 0.2rem;
        margin-top: 0rem;
        li.qiyunchild {
          margin-right: 0.01rem;
          font-size: 0.25rem;
          width: 1rem;
          .qiyunchild-shishen {
            font-size: 0.25rem;
          }
          .qiyunchild-ganzhi {
            font-size: 0.35rem;
            letter-spacing: 0px; /* 设置字母间距为5px */
          }
          .qiyunchild-nianling {
            display: block;
          }
        }
      }

      ul.ziweidoushu {
        margin: 0.1rem auto;

        li {
          font-size: 0.5rem;
          line-height: 0.5rem;
        }
      }
    }
    .small-square {
      ul {
        margin-top: -0.1rem;
        li span {
          font-size: 0.4rem;
        }
      }

      //主星、副星、丙级星
      ul.zx_zh li,
      ul.fx_zh li,
      ul.x5_zh li {
        width: 1.9vw;
        min-width: 0rem;
        line-height: 0.2rem;
        font-size: 0.4rem;
        transform: scale(0.9);
        span {
          font-size: 0.4rem;
          height: 0.45rem;
        }
        span.x1_zw,
        span.x5_fx,
        span.x2_bj,
        span.x_mx,
        span.x_sh {
          width: 1.5vw;
          line-height: 0.45rem;
          transform: scale(0.9);
        }
      }
      //岁前、将前、博士
      ul.bs-sq-jq {
        li {
          height: 0.4rem;
          transform: scale(0.8);
          span {
            font-size: 0.32rem;
          }
        }
      }
      //先天到流时的宫名
      ul.ls-lr-ly li,
      ul.ln-yg-mg li {
        height: 0.4rem;
        transform: scale(0.8);
        span {
          font-size: 0.4rem;
        }
      }
      //大限年龄段
      ul.dx-nld {
        top: 65%;
        li {
          height: 0.4rem;
          font-size: 0.4rem;
          transform: scale(0.8);
          span {
            font-size: 0.4rem;
            transform: scale(0.8);
          }
        }
      }
      ul.yf-xy-gz li {
        height: 0.45rem;
        transform: scale(0.8);
        span {
          font-size: 0.4rem;
          transform: scale(0.8);
        }
      }

      //安星系天干
      ul.axxtg {
        li {
          span {
            font-size: 0.4rem;
            width: 0.05rem;
            transform: scale(0.9);
          }
        }
      }
    }
  }

  div.gongjuqu {
    span.childspan {
      font-size: 0.55rem;
      margin-left: 0.1rem;
      div.van-checkbox {
        span.van-checkbox__label {
          width: 20px;
        }
      }
      .van-button {
        height: 17px;
        margin-top: 2px;
      }
      .van-button__icon {
        font-size: 0.6rem;
      }
      .van-button--mini {
        font-size: 7px;
      }
    }
  }

  div.daxianliunian {
    ul {
      li {
        dl {
          dt {
            width: 1.45rem;
          }
          dd {
            width: auto;

            em {
              font-size: 0.5rem;
            }
          }
        }
      }

      li.daxian {
        dl {
          dt {
            height: 1.5rem;
          }

          dd {
            span {
              width: 1.52rem;
            }

            span:nth-child(10),
            span:nth-child(11),
            span:nth-child(12) {
              em:nth-child(1) {
                zoom: 0.76;
                margin-top: 0.25rem;
                margin-bottom: 0.25rem;
              }
            }
          }
        }
      }

      li.liunian {
        dl {
          dt {
            height: 1.5rem;
          }

          dd {
            span {
              width: 1.82rem;
            }
          }
        }
      }

      li.liuyue {
        dl {
          dt {
            height: 1.5rem;
          }

          dd {
            span {
              width: 1.52rem;
            }
          }
        }
      }

      li.liuri {
        dl {
          dt {
            height: 3.1rem;
          }

          dd {
            float: left;

            span {
              width: 1.2rem;
            }

            span:nth-child(15) {
              border-right: 0px;
            }

            span:nth-child(16) {
              clear: both;
            }

            span:nth-child(-n + 15) {
              border-bottom: 1px solid #000;
            }
          }
        }
      }

      li.liushi {
        dl {
          dt {
            height: 1.5rem;
          }

          dd {
            float: left;

            span {
              width: 1.5rem;
            }
          }
        }
      }
    }
  }
}
//手机端微信端样式
@media screen and (min-width: 381px) and (max-width: 580px) {
  /*手机端微信端样式*/
  div.bigzwdsbox {
    .large-square {
      ul.basicinfo {
        width: 100%;
        li {
          font-size: 0.35rem;
          line-height: 0.6rem;
        }

        li span {
          display: block;
          float: left;
          width: 30%;
          font-size: 0.35rem;
          //margin-left: 0.2rem;
        }
      }

      ul.sizhubazi {
        li.bazichild {
          font-size: 0.4rem;
          display: block;
          float: left;
          width: 1.8rem;
          line-height: 0.5rem;
          margin-left: 1%;

          dl {
            dt {
              text-align: center;
            }

            dd {
              text-align: center;
            }
          }

          span {
            text-align: center;
            clear: both;
            display: block;
          }

          span.bazi {
            align-items: center;
            word-wrap: break-word;
            font-size: 0.7rem;
            line-height: 0.8rem;
            margin: 0 auto;
            font-weight: bolder;
          }
        }
      }

      ul.baziqiyun {
        margin-top: 0.1rem;
        margin-left: 0.3rem;
        margin-top: 0rem;
        li.qiyunchild {
          margin-right: 0.2rem;
          font-size: 0.45rem;
          width: 1rem;
          .qiyunchild-shishen {
          }
          .qiyunchild-ganzhi {
            font-size: 0.6rem;
            letter-spacing: 2px; /* 设置字母间距为5px */
          }
          .qiyunchild-nianling {
            font-size: 0.45rem;
          }
        }
      }
    }

    .small-square {
      //主星、副星、丙级星
      ul.zx_zh li,
      ul.fx_zh li,
      ul.x5_zh li {
        width: 1.9vw;
        min-width: 0rem;
        line-height: 0.25rem;
        span {
          font-size: 0.4rem;
          height: 0.45rem;
        }
        span.x1_zw,
        span.x5_fx,
        span.x2_bj,
        span.x_mx,
        span.x_sh {
          width: 1.5vw;
          line-height: 0.45rem;
        }
      }
      //岁前、将前、博士
      ul.bs-sq-jq {
        li {
          height: 0.45rem;
          span {
            font-size: 0.4rem;
          }
        }
      }
      //先天到流时的宫名
      ul.ls-lr-ly li,
      ul.ln-yg-mg li {
        height: 0.45rem;
        span {
          font-size: 0.4rem;
        }
      }
      //大限年龄段
      ul.dx-nld {
        top: 55%;
        li {
          height: 0.45rem;
          font-size: 0.4rem;
          span {
            font-size: 0.4rem;
          }
        }
      }
      ul.yf-xy-gz li {
        height: 0.45rem;
        span {
          font-size: 0.4rem;
        }
      }
      //安星系天干
      ul.axxtg {
        li {
          span {
            font-size: 0.4rem;
            width: 0.1rem;
          }
        }
      }
    }
  }

  div.gongjuqu {
    span.childspan {
      font-size: 0.55rem;
      margin-left: 0.2rem;
      div.van-checkbox {
        span.van-checkbox__label {
          width: 25px;
        }
      }
      .van-button {
        height: 17px;
        margin-top: 2px;
      }
      .van-button__icon {
        font-size: 0.6rem;
      }
      .van-button--mini {
        font-size: 7px;
      }
    }
  }

  div.daxianliunian {
    ul {
      li {
        dl {
          dd {
            width: auto;

            em {
              font-size: 0.5rem;
            }
          }
        }
      }

      li.daxian {
        dl {
          dt {
            height: 1.5rem;
          }

          dd {
            span {
              width: 1.73rem;
            }

            span:nth-child(11) {
              em:nth-child(1) {
                zoom: 0.87;
                margin-bottom: 0.28rem;
              }
            }

            span:nth-child(12) {
              em:nth-child(1) {
                zoom: 0.87;
                margin-bottom: 0.28rem;
              }
            }
          }
        }
      }

      li.liunian {
        dl {
          dt {
            height: 1.5rem;
          }

          dd {
            span {
              width: 2.05rem;
            }
          }
        }
      }

      li.liuyue {
        dl {
          dt {
            height: 1.5rem;
          }

          dd {
            span {
              width: 1.72rem;
            }
          }
        }
      }

      li.liuri {
        dl {
          dt {
            height: 3.1rem;
          }

          dd {
            float: left;

            span {
              width: 1.373rem;
            }

            span:nth-child(15) {
              border-right: 0px;
            }

            span:nth-child(16) {
              clear: both;
            }

            span:nth-child(-n + 15) {
              border-bottom: 1px solid #000;
            }
          }
        }
      }

      li.liushi {
        dl {
          dt {
            height: 1.5rem;
          }

          dd {
            float: left;

            span {
              width: 1.735rem;
            }
          }
        }
      }
    }
  }
  //日历样式
  div.rili_box {
    span {
      margin-left: 1rem;
      font-size: 0.5rem;
      line-height: 0.5rem;
    }
  }
}
//平板样式
@media screen and (min-width: 581px) and (max-width: 1080px) {
  /*平板样式*/
  /*平板样式*/

  div.bigzwdsbox {
    width: 600px;
    height: 600px;

    .large-square {
      font-size: 0.7rem;

      ul.basicinfo {
        min-width: 203px;

        li {
          font-size: 0.7rem;
        }

        li span {
          display: block;
          float: left;
          width: 30%;
          font-size: 0.6rem;
          //margin-left: 0.2rem;
        }
      }

      ul.sizhubazi {
        li.bazichild {
          font-size: 0.5rem;
          display: block;
          float: left;
          width: 2.5rem;
          line-height: 0.9rem;
          margin-left: 0.5rem;

          dl {
            dt {
              text-align: center;
            }

            dd {
              text-align: center;
            }
          }

          span {
            text-align: center;
            clear: both;
            display: block;
          }

          span.bazi {
            align-items: center;
            word-wrap: break-word;
            font-size: 0.9rem;
            line-height: 0.8rem;
            margin: 0 auto;
            font-weight: bolder;
          }
        }
      }
      ul.baziqiyun {
        margin-top: 0.1rem;
        margin-left: 0.5rem;
        margin-top: 0.2rem;
        li.qiyunchild {
          margin-right: 0.4rem;
          font-size: 0.55rem;
          width: 1.1rem;
          .qiyunchild-shishen {
          }
          .qiyunchild-ganzhi {
            font-size: 0.8rem;
            letter-spacing: 2px; /* 设置字母间距为5px */
          }
          .qiyunchild-nianling {
            font-size: 0.55rem;
          }
        }
      }
    }
    .small-square {
      //主星、副星、丙级星
      ul.zx_zh li,
      ul.fx_zh li,
      ul.x5_zh li {
        span.x1_zw,
        span.x5_fx,
        span.x2_bj,
        span.x_mx,
        span.x_sh {
          height: 12px;
          line-height: 12px;
        }
      }

      //安星系天干
      ul.axxtg {
        li {
          span {
            font-size: 0.7rem;
          }
        }
      }
    }
  }

  div.gongjuqu {
    width: 600px;
    span.childspan {
      font-size: 0.65rem;
    }
  }

  div.rili_box {
    width: 600px;
    span {
      margin-left: 1rem;
      font-size: 0.7rem;
      line-height: 0.7rem;
    }
  }
  div.daxianliunian {
    width: 600px;

    ul {
      li {
        dl {
          dd {
            width: auto;

            em {
              font-size: 0.7rem;
            }
          }
        }
      }

      li.daxian {
        dl {
          dt {
            height: 1.5rem;
          }

          dd {
            span {
              //width:46.8px ;
              width: 2.86rem;
            }

            span:nth-child(11) {
              em:nth-child(1) {
                font-size: 13px;
                zoom: 0.89;
              }
            }

            span:nth-child(12) {
              em:nth-child(1) {
                font-size: 13px;
                zoom: 0.89;
              }
            }
          }
        }
      }

      li.liunian {
        dl {
          dt {
            height: 1.5rem;
          }

          dd {
            span {
              width: 3.4rem;
            }
          }
        }
      }

      li.liuyue {
        dl {
          dt {
            height: 1.5rem;
          }

          dd {
            span {
              width: 2.85rem;
            }
          }
        }
      }

      li.liuri {
        dl {
          dt {
            height: 3.1rem;
          }

          dd {
            float: left;

            span {
              width: 2.273rem;
            }

            span:nth-child(15) {
              border-right: 0px;
            }

            span:nth-child(16) {
              clear: both;
            }

            span:nth-child(-n + 15) {
              border-bottom: 1px solid #000;
            }
          }
        }
      }

      li.liushi {
        dl {
          dt {
            height: 1.5rem;
          }

          dd {
            float: left;

            span {
              width: 2.85rem;
            }
          }
        }
      }
    }
  }
}

//普通浏览器样式
@media screen and (min-width: 1081px) and (max-width: 1920px) {
  /*PC端样式 当尺寸大于设定尺寸时*/

  div.bigzwdsbox {
    width: 700px;
    height: 700px;

    .large-square {
      font-size: 0.7rem;

      ul.basicinfo {
        li {
          span.whole {
            width: 90%;
          }
        }
      }
      ul.sizhubazi {
        li.bazichild {
          span.bazi {
            font-size: 1rem;
          }
        }
      }
    }
    .small-square {
      //安星系天干
      ul.axxtg {
        li {
          span {
            font-size: 0.7rem;
          }
        }
      }
    }
  }

  div.gongjuqu {
    width: 700px;

    span.childspan {
      font-size: 0.75rem;
      .van-checkbox {
        margin-top: 0.2rem;
      }
      .van-button {
        height: 25px;
      }
    }
  }

  div.daxianliunian {
    width: 700px;
  }
  div.rili_box {
    width: 700px;
    span {
      margin-left: 1rem;
      font-size: 0.7rem;
      line-height: 0.7rem;
    }
  }
}

//大屏幕浏览器样式
@media screen and (min-width: 1921px) {
  /*PC端样式 当尺寸大于设定尺寸时*/
  div.bigzwdsbox {
    width: 800px;
    height: 800px;
    .small-square {
      ul.axxtg {
        li {
          span {
            font-size: 0.7rem;
          }
        }
      }
    }
  }

  div.gongjuqu {
    width: 800px;
    span.childspan {
      font-size: 0.8rem;
    }
  }

  div.daxianliunian {
    width: 800px;
  }
  div.rili_box {
    width: 800px;

    span {
      margin-left: 1rem;
      font-size: 0.7rem;
      line-height: 0.7rem;
    }
  }
}
</style>
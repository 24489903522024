

<template>
  <div class="box">
    <component @ComNameFromSon="SetComName" :is="comName"></component>
    <hr />
    <div
      class="thirdLogin"
      v-if="LoginUser.UserId === null || LoginUser.UserId === ''"
    >
      <!-- <h3>档案登录</h3>
      <a
        :href="
          this.$webUrl +
          '/Member/Login/WechatLogin?returnUrl=' +
          this.$zwdsppuiUrl
        "
        ><img
          class="wechat-img"
          :src="this.$webUrl + '/Static/images/img08.png'"
          alt=""
      /></a> -->
    </div>

    <van-tabbar v-model="active" @change="tabbarChange">
      <van-tabbar-item>
        <span>首页</span>
        <template #icon="props">
          <img src="https://www.chinazwds.com/Static/images/f_img01.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>排盘</span>
        <template #icon="props">
          <img src="https://www.chinazwds.com/Static/images/f_img06_on.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>发布</span>
        <template #icon="props">
          <img src="https://www.chinazwds.com/Static/images/f_img03.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>服务</span>
        <template #icon="props">
          <img src="https://www.chinazwds.com/Static/images/f_img04.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>我的</span>
        <template #icon="props">
          <img src="https://www.chinazwds.com/Static/images/f_img05.png" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<style lang="less">
div.thirdLogin {
  margin-top: 2rem;
  text-align: center;

  .wechat-img {
    width: 3rem;
  }
}
</style>
<script>
import Vue from "vue";
import { Toast } from "vant";
import { Notify } from "vant";
import UserInfo from "@/components/UserInfo.vue";
import ZwdsPP from "@/components/ZwdsPP.vue";
import Test from "@/components/Test.vue";
import bus from "@/components/Js/EventBus.js";
export default {
  data() {
    return {
      //tabbar 状态
      active: 1,
      icon: {
        active: "https://img01.yzcdn.cn/vant/user-active.png",
        inactive: "https://img01.yzcdn.cn/vant/user-inactive.png",
      },

      //定义组件名称，用来接受子组件传递过来的值
      comName: "UserInfo", //组件名称 默认为UserInfo
      comArchi: "Archives", //档案组件 默认为空
      //定义userinfo的变量，来接受UserInfo组件传过来的值
      UserInfoForChild: {
        Name: "",
        Sex: "", //性别：0-男，1-女
        Birthday: "",
        Hours: 0,
      },
      //定义登录用户的变量
      LoginUser: {
        UserId: "",
        CHName: "",
        Account: "",
      },
      USERINFOShow: false,
      ZWPPShow: true,
    };
  },
  created() {
    /**
     * access_token变量获取的逻辑
     * 1、检测是否有请求参数token，如有，则将本地缓存设置为请求参数token，并跳转至2.2节点
     * 2、如果请求参数不存在token，则检查本地缓存是否为空，如果为空，则跳转至登录页面
     * 2.1、跳转登录页面，并携带参数，参数为：returnUrl=当前页面地址
     * 2.2、拿到请求参数后，获取token，用得到的token请求认证中心接口，获取用户信息
     * 2.3、如果用户信息正确，则给本地缓存变量
     */
    const req_access_token = this.$route.query.access_token; //请求类型获取token
    if (req_access_token) {
      //如果请求参数中带有token
      sessionStorage.setItem("access_token", req_access_token);
      Vue.prototype.$access_token = sessionStorage.getItem("access_token");
    } else {
      //console.log("未检测到请求参数！");
      //console.log("从session变量中拿token：" + sessionStorage.getItem("access_token"));
      //如果请求参数中没有token
      Vue.prototype.$access_token = sessionStorage.getItem("access_token");
      if (Vue.prototype.$access_token) {
        //console.log("token数据存在！");
      } else {
        //console.log("token数据不存在，跳转至登录页面");
      }
    }

    if (Vue.prototype.$access_token) {
      //console.log("access_token存在，开始获取用户信息");
      this.$nextTick(async function () {
        try {
          const { data: res } = await this.$http({
            headers: {
              "Content-Type": "multipart/form-data",
            },
            method: "post",
            url:
              this.$autherUrl + "/Home/GetWechatUser?t=" + new Date().getTime(),
            params: {
              access_token: Vue.prototype.$access_token,
            },
          });
          if (res.success) {
            //console.log(res.data);
            //Toast("登录成功：用户" + res.data.UserId);
          }
          if (!res.success) {
            Toast("业务失败，原因：" + res.msg);
            //console.log("业务失败，原因：" + res.msg);
            return false;
          }
        } catch (err) {
          //Toast("发生错误，原因：" + err.message);
        }
      });
    }
  },
  mounted() {},
  methods: {
    //点击导航切换
    tabbarChange(index) {
      console.log(index);
      if (index == 0) {
        window.location.href = Vue.prototype.$webUrl;
      }
      if (index == 2) {
        window.location.href = Vue.prototype.$webUrl;
      }
      if (index == 3) {
        window.location.href = Vue.prototype.$webUrl + "/";
      }
      if (index == 4) {
        window.location.href =
          Vue.prototype.$webUrl +
          "/Member?returnUrl=" +
          Vue.prototype.$zwdsppuiUrl;
      }
      //Notify({ type: "primary", message: index });
    },
    // 写入cookie
    setCache() {
      //设置cookies，单位为天
      this.$cookie.set("cookie_name", "cookie_value", { expires: 7 });
      console.log("设置cookie");
    },
    // 删除cookie
    removeCache() {
      this.$cookie.remove("cookie_name");
    },
    readCookie() {
      // 读取cookie
      const value = this.$cookie.get("cookie_name");
      if (value) {
        // 如果cookie存在，进行缓存处理
        console.log("Cookie value:", value);
      }
    },

    //默认展示UserInfo组件
    //点击确认，加载排盘组件
    SetUserInfo(val) {
      console.log("隐藏UserInfo组件,显示排盘组件");
    },
    //设置组件名称
    SetComName(val) {
      this.comName = val;
    },
  },
  //注册组件
  components: {
    Test,
    UserInfo,
    ZwdsPP,
  },
};
</script>
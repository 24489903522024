<template>
  <div id="container-can">
    <canvas id="triangleCanvas" ref="triangleCanvas"></canvas>
  </div>
</template>

<script>
//该组件中，还需要传入宽度参数和宫位数字参数
export default {
  //自定义属性
  props: {
    //定义init 为一个对象类型的自定义属性
    init_Width: {
      Type: Number,
      default: 300,
    },
    init_Quare_Width: {
      Type: Number,
      default: 0,
    },
    init_Quare_Height: {
      Type: Number,
      default: 0,
    },
  },
  data() {
    return {
      line: null, //三角形数据对象数组
      gonggenum: 1, //宫格数字，默认为紫微星所在位置
      original_width: 300, //初始宽度
      quareWidth: 0, //大方块宽度
      quareHeight: 0, //大方块高度;
    };
  },
  created() {},
  //在html结构渲染到浏览器以后，初始化函数
  mounted() {
    this.original_width = this.init_Width;
    this.quareWidth = this.init_Quare_Width;
    this.quareHeight = this.init_Quare_Height;

    const canvas = this.$refs.triangleCanvas;
    const ctx = canvas.getContext("2d");
    // 将Canvas的实际宽度和高度设置为获取到的值
    canvas.width = this.quareWidth == 0 ? 352 : this.quareWidth;
    canvas.height = this.quareHeight == 0 ? 352 : this.quareHeight;
    //更新三角形线起点、终点数据
    this.updateLine(this.gonggenum, canvas.width);
    window.addEventListener("resize", this.handleResize);
    //输出页面宽度
    //alert(window.innerWidth);
  },
  methods: {
    /*更新三角数据*/
    updateLine(gwxh, width) {
      //更新数组中的数字
      this.line = [
        [
          [224, 300],
          [0, 76],
          [300, 0],
          [76, 0],
        ], //1
        [
          [76, 300],
          [0, 0],
          [300, 76],
          [224, 0],
        ], //2
        [
          [0, 300],
          [76, 0],
          [300, 224],
          [300, 0],
        ], //3
        [
          [0, 224],
          [224, 0],
          [300, 300],
          [300, 76],
        ], //4
        [
          [0, 76],
          [300, 0],
          [224, 300],
          [300, 224],
        ], //5
        [
          [0, 0],
          [300, 76],
          [76, 300],
          [300, 300],
        ], //6
        [
          [76, 0],
          [300, 224],
          [0, 300],
          [224, 300],
        ], //7
        [
          [228, 0],
          [300, 300],
          [0, 224],
          [76, 300],
        ], //8
        [
          [300, 0],
          [224, 300],
          [0, 76],
          [0, 300],
        ], //9
        [
          [300, 76],
          [76, 300],
          [0, 0],
          [0, 224],
        ], //10
        [
          [300, 224],
          [0, 300],
          [76, 0],
          [0, 76],
        ], //11
        [
          [300, 300],
          [0, 224],
          [224, 0],
          [0, 0],
        ], //12
      ];
      const bili = width / 302;

      for (let i = 0; i < this.line.length; i++) {
        for (let j = 0; j < this.line[i].length; j++) {
          for (let k = 0; k < this.line[i][j].length; k++) {
            this.line[i][j][k] *= bili;
          }
        }
      }
      this.drawTriangle(gwxh, width, width);
    },

    //绘制三角形图形
    drawTriangle(gwxh, quareWidth, quareHeight) {
      //三角形触发
      const canvas = document.getElementById("triangleCanvas");
      const ctx = canvas.getContext("2d");

      canvas.width = quareWidth;
      canvas.height = quareHeight;
      if (gwxh == "") {
        gwxh = 0;
      }
      //因为数组的下标是从0开始，所以宫位序号需要-1
      gwxh = gwxh - 1;
      this.gonggenum = gwxh;
      // 清除上一次绘制的三角形
      ctx.clearRect(0, 0, 352, 352);

      // 绘制旋转后的三角形
      ctx.save(); // 保存当前状态
      //ctx.translate(canvas.width / 2, canvas.height / 2); // 将坐标原点移动到中心点

      var line = this.line;
      ctx.rotate((this.angle * Math.PI) / 180); // 旋转坐标系
      ctx.beginPath(); //开始路径
      ctx.moveTo(line[gwxh][0][0], line[gwxh][0][1]); // 画笔移动至起点（中心位置）
      ctx.lineTo(line[gwxh][1][0], line[gwxh][1][1]); // 绘制第一条边
      ctx.lineTo(line[gwxh][2][0], line[gwxh][2][1]); // 绘制第二条边
      ctx.lineTo(line[gwxh][0][0], line[gwxh][0][1]); // 绘制第三条边
      ctx.moveTo(line[gwxh][0][0], line[gwxh][0][1]); // 画笔移动至起点（中心位置）
      ctx.lineTo(line[gwxh][3][0], line[gwxh][3][1]); // 绘制直线
      ctx.lineWidth = 1.5;
      ctx.strokeStyle = "#f7b0df";
      ctx.stroke(); // 绘制三角形
      ctx.restore(); // 恢复到保存的状态（取消旋转）
    },
  },
};
</script>

<style lang="less" scoped>
//大方格三角样式
canvas#triangleCanvas {
  top: 0;
  left: 0;
  z-index: -1;
  position: absolute;
}
</style>